import * as React from 'react';
import {useCallback, useState} from 'react';
import {Dialog_ConfirmationRename} from "@components/Dialog_ConfirmationRename";
import {css} from "emotion";
import {icon__leftArrow, icon__rightArrow} from "../units/utils";
import {SimpleLoader} from "@components/SimpleLoader";
import {
    ActivationsModule,
    RequestKey_DeleteActivations,
    RequestKey_RegisterActivation,
    RequestKey_RenameActivation,
    RequestKey_UpdateActivations,
    URL_ActivationsDelete
} from "@modules/ActivationsModule";
import {BaseComponent, DialogModule, RoutingModule, TooltipBuilder, TooltipDefaultStyle, TooltipModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Route_UnitMonitoring, Url_AllPermitted} from "../../routes";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {authType, AuthType, Body_Activation, Body_DeleteActivation, Body_UpdateActivationWithMetadata, DBActivationsInterface} from "@app/ir-q-app-common/types/activation";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {getCurrentUserEmail} from "../../App";
import {ConfirmationModule} from "@modules/confirmation-modal-module";
import {CopyButton} from "../../renderers/CopyButton";
import {ContactListModule} from "@modules/ContactListModule";
import {PackageManagerModule} from '@modules/package-manager/PackageManagerModule';
import {Alias} from '@app-sp/app-shared/package-manager';
import {CareCenterModule} from '@modules/CareCenterModule';
import {UnitsModule} from "@modules/UnitsModule";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {ActivationDefaultParameters, ActivationFormComponent} from './ActivationFormComponent';
import {ActivationRow, createNewBtnStyle} from './Page_ActivationsList';
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from '@app/ir-q-app-common/types/unit-config';
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {UserGroupTypes} from "@app/ir-q-app-common/types/units";
const icon__warning = require('@res/images/icon__warning.svg')

const cellStyle = css({boxShadow: "0px -1px 0px 0px #11111114 inset", padding: 5, cursor: "pointer"})
const dialogWrapper = css({
    backgroundColor: "#c9c9c9c9",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const showTooltip = (e: React.MouseEvent<HTMLElement>, content: React.ReactNode, xOffset?: number) => {
    new TooltipBuilder(content, e)
        .setLocation(e.clientX + (xOffset || 15), e.clientY - 15)
        .setStyle({...TooltipDefaultStyle, zIndex: 100})
        .setDuration(4000)
        .show();
}
const getAuthTypeText = (authTypeKey?: AuthType | undefined) => {
    if (!authTypeKey)
        return "";

    return authType[authTypeKey];
}

export const disenrolledOption = {key: "disenrolled", value: "Disenrolled"};
export const cancelledOption = {key: "cancelled", value: "Cancelled"};

type State = {
    page: number,
    editAgentId: string
    renameUnitId: string
    showPincodeRefresh?: boolean
    askRename?: string
    shouldKeepPrivateData?: boolean
    renameReason?: string
    assignSerial?: boolean
    loading: boolean
}

type Props = {
    data: ActivationRow[]
    loading: boolean,
    isHistory: boolean
}

export class ActivationTable
    extends BaseComponent<Props, State> implements OnRequestListener {
    private maxPerPage: number = 50;
    private headers: (keyof ActivationRow)[] = ["created", "firstName", "lastName", "unitId", "som_serial", 'tablet_serial', 'auditBy', 'authType', 'activated', 'actions'];

    constructor(props: Props) {
        super(props);
        this.state = {
            page: 0,
            editAgentId: "",
            renameUnitId: "",
            loading: false
        };
    }

    __onRequestCompleted(key: string, success: boolean, requestData?: string): void {
        if (!success)
            return;

        switch (key) {
            case RequestKey_DeleteActivations:
                this.setState({loading: false})
                break;
            case RequestKey_RenameActivation:
                this.setState({renameUnitId: "", askRename: undefined, shouldKeepPrivateData: undefined});
                break;
            case RequestKey_UpdateActivations:
            case RequestKey_RegisterActivation:
                this.setState({editAgentId: "", assignSerial: false, loading: false});
        }
    }

    render() {
        const renameUnitId = this.state.askRename;
        return <div className={"ll_v_c match_width"}
                    style={{
                        overflow: "hidden",
                        height: "100%",
                        boxSizing: 'border-box',
                        padding: 30,
                        paddingTop: 15,
                        backgroundColor: "#F2F2F2"
                    }}>
            {this.getTable()}
            {this.renderPagination()}
            {this.renderRenamePopup()}
            {this.renderEditPopup()}
            {renameUnitId && <Dialog_ConfirmationRename
                agent_id={renameUnitId}
                onAccept={(shouldKeepPrivateData: boolean, reason?: string) => {
                    this.setState({askRename: undefined, renameUnitId, shouldKeepPrivateData, renameReason: reason});
                }}
                onCancel={() => this.setState({askRename: undefined})}
                inputFieldIsMandatory={true}
                showInput={true}
                inputMandatoryText={"The reason field is mandatory"}
            />}
            {this.renderLoader()}
        </div>
    }

    private getTable() {
        const dev = this.props.data;
        const tableStyle = css({
            height: `100%`,
            width: '100%',
            borderCollapse: 'collapse'
        })

        const headerStyle = css({
            backgroundColor: "white",
            height: "43px"
        });

        const thClass = css({
            height: "41px",
            textAlign: "left",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1000,
            boxShadow: "0px -1px 0px 0px #11111166 inset"
        });
        const devices = dev.slice(this.maxPerPage * this.state.page, this.maxPerPage * (this.state.page + 1));

        return <div
            className={"ll_h_c match_width"}
            style={{display: "block", justifyContent: "center", width: "100%", overflow: "scroll"}}
        >
            <table className={tableStyle}>
                <thead className={headerStyle}>
                <tr>
                    {this.headers.map((header, i) => {
                        let label: string = header;
                        switch (header) {
                            case "activated":
                                label = 'Activated';
                                break;
                            case "created":
                                label = "Date Created";
                                break;
                            case "firstName":
                                label = "First Name";
                                break;
                            case "lastName":
                                label = "Last Name";
                                break;
                            case "unitId":
                                label = "Unit ID";
                                break;
                            case "som_serial":
                                label = "SOM SN";
                                break;
                            case "tablet_serial":
                                label = "Tablet SN";
                                break;
                            case "auditBy":
                                label = "Edited by";
                                break;
                            case "authType":
                                label = "Auth. type";
                                break;
                            case "actions":
                                label = "";
                                break;
                        }
                        return <th style={label === "" ? {paddingLeft: "25px"} : {}} className={thClass} key={header}>{label}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {devices.map((row, idx) => this.renderRow(row, idx))}
                </tbody>
            </table>
        </div>;
    }

    private renderPagination() {
        const dev = this.props.data;

        const devicesLength = dev.length
        const maxPagesPerLegend = 5;
        const delta = Math.floor(maxPagesPerLegend / 2);
        const maxPage = Math.ceil(devicesLength / this.maxPerPage)
        const maxFirstPage = maxPage - maxPagesPerLegend + 1;
        const firstPage = this.state.page <= delta ? 0 : (this.state.page < maxFirstPage + delta ? this.state.page - delta : maxFirstPage);
        const howManyPages = Math.min(maxPage, maxPagesPerLegend);
        if (howManyPages === 0)
            return;

        const className = css`
            background: linear-gradient(0deg, #E8E8E8, #E8E8E8),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
            border: 1px solid #E8E8E8;
            width: 32px;
            height: 32px;
            padding: 5px 4px 5px 4px;
            border-radius: 4px;
            margin: 0 2px;
            cursor: pointer;
            min-width: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
        `;
        const cursorClass = css`
            ${className};
            font-size: 80%;
        `

        return <div style={{width: "100%", marginTop: "10px"}} className={"ll_h_c"}>
            <div
                style={this.state.page >= 1 ? {background: "white"} : {}}
                className={cursorClass}
                onClick={() => this.setState(_state => ({page: Math.max(_state.page - 1, 0)}))}
            >
                <img alt={'left'} src={icon__leftArrow}/>
            </div>
            {Array(howManyPages)
                .fill(0)
                .map((e, i) => {
                    return <div
                        className={className}
                        style={this.state.page === firstPage + i ? {
                            color: "#487CFF",
                            background: "#FFFFFF",
                            border: "1px solid #487CFF"
                        } : {}}
                        key={i}
                        onClick={() => this.setState({page: firstPage + i})}>
                        {firstPage + i + 1}
                    </div>;
                })}
            <div
                style={this.state.page < howManyPages - 1 ? {background: "white"} : {fill: "white"}}
                className={cursorClass}
                onClick={() => this.setState(_state => ({page: Math.min(_state.page + 1, maxPage - 1)}))}
            >
                <img alt={'right'} src={icon__rightArrow}/>
            </div>
        </div>;
    }

    private renderLoader() {
        if (!this.props.loading && !this.state.loading)
            return null;

        return <SimpleLoader overlay={true}/>
    }

    renderEditPopup(): React.ReactNode {
        if (!this.state.editAgentId)
            return null;

        const aliases = PackageManagerModule.getAliases();
        const unitToAliasObj = PackageManagerModule.getUnits();

        let userGroup, subUserGroup;
        UnitsModule.getUnitMetadata(this.state.editAgentId)?.forEach(meta => {
            if (meta.dataKey === CONST_KS_UserGroup) {
                userGroup = meta.data;
            }
            if (meta.dataKey === CONST_KS_SubUserGroup) {
                subUserGroup = meta.data;
            }
        });
        const agentUser = ContactListModule.getItems().find(c => c.contactData.unitId === this.state.editAgentId);
        const unitToAlias = Object.values(unitToAliasObj).find(item => item.unitId === this.state.editAgentId);
        const aliasesValues = Object.values(aliases);
        const alias = aliasesValues.find(item => item.id === unitToAlias?.aliasId);
        if (!(agentUser && aliasesValues.length))
            return;

        const row = this.props.data.find(d => d.unitId === this.state.editAgentId);

        if (!row)
            return;

        const existActivationFormData: ActivationDefaultParameters = {
            unitId: row.unitId,
            somSerial: row.som_serial,
            tabletSerial: row.tablet_serial,
            replacementTabletSerial: row.replacement_tablet_serial as string | undefined,
            birthday: agentUser.birthday, // Jan 1, 1970 (ms)
            firstName: row.firstName,
            lastName: row.lastName,
            gender: agentUser.gender,
            middleName: agentUser.middleName,
            phoneNumber: agentUser.contactData.phoneNumber,
            areaCode: agentUser.contactData.areaCode,
            email: agentUser.contactData.email,
            userGroup,
            subUserGroup,
            country: agentUser.address?.country,
            city: agentUser.address?.city,
            state: agentUser.address?.state,
            region: agentUser.address?.region,
            streetAddress: agentUser.address?.streetAddress,
            postalCode: agentUser.address?.postalCode,
            extendedAddress: agentUser.address?.extendedAddress,
            alias: alias?.id,
            aliasName: alias?.name,
            pincode: row.pincode,
            activated: row.activated,
            authType: row.authType
        }

        if (row.activated !== undefined) {
            const activation = ActivationsModule.getActivation(this.state.editAgentId);
            existActivationFormData.id = activation?.id;
            existActivationFormData.som_pk = activation?.som_pk;
            existActivationFormData.tablet_pk = activation?.tablet_pk;
        }

        const patient = CareCenterModule.getUnitPatient(this.state.editAgentId);
        if (patient) {
            existActivationFormData.organizationId = patient.organizationId;
            if (patient.physicianId)
                existActivationFormData.physicianId = patient.physicianId;
            if (patient.careManagerId)
                existActivationFormData.careManagerId = patient.careManagerId;
        } else {
            existActivationFormData.organizationId = agentUser.contactData.organizationId;
            if (agentUser.contactData.physicianId)
                existActivationFormData.physicianId = agentUser.contactData.physicianId;
            if (agentUser.contactData.careManagerId)
                existActivationFormData.careManagerId = agentUser.contactData.careManagerId;
        }

        // TODO: remove this after all units will have state instead of region
        if (existActivationFormData.region && !existActivationFormData.state) {
            existActivationFormData.state = existActivationFormData.region;
            delete existActivationFormData.region;
        }

        return <div className={dialogWrapper}>
            <div style={{backgroundColor: "white"}}>
                <img width={12} height={12} style={{cursor: "pointer", marginTop: "10px", marginLeft: "10px"}}
                     alt={'delete'} src={require("@res/images/icon__x.svg")} onClick={() => {
                    DialogModule.close();
                    this.setState({editAgentId: "", assignSerial: false});
                }}/>
                <ActivationFormComponent editMode={!this.state.assignSerial}
                                         defaultParams={existActivationFormData}
                                         hideUserOnlyToggle={true}
                                         userOnly={row.activated === undefined && !this.state.assignSerial}
                                         onSubmit={activationData => {
                                             if (this.state.assignSerial) {
                                                 this.deleteIrrelevantFieldsForRegisterApi(activationData);
                                                 ActivationsModule.activate(activationData, true)
                                                 return;
                                             }

                                             const updateData = {
                                                 ...activationData,
                                                 agent_id: activationData.unitId,
                                                 som_device_id: activationData.somSerial,
                                                 tablet_device_id: activationData.tabletSerial,
                                                 replacement_tablet_device_id: activationData.replacementTabletSerial,
                                                 auth_type: activationData.authType,
                                                 pincode: existActivationFormData.pincode,
                                                 blame: getCurrentUserEmail(),
                                                 activated: existActivationFormData.activated,
                                                 id: existActivationFormData.id,
                                                 som_pk: existActivationFormData?.somSerial === activationData.somSerial ? existActivationFormData?.som_pk : null,
                                                 tablet_pk: existActivationFormData?.tabletSerial === activationData.tabletSerial ? existActivationFormData?.tablet_pk : null
                                             } as Body_UpdateActivationWithMetadata;
                                             this.deleteIrrelevantFieldsForUpdateApi(updateData);
                                             return ActivationsModule.updateWithMetadata(updateData);
                                         }}/>
            </div>
        </div>;
    }

    deleteIrrelevantFieldsForRegisterApi = (activationData: Body_Activation) => {
        // @ts-ignore
        delete activationData.replacementTabletSerial;
    }
    deleteIrrelevantFieldsForUpdateApi = (updateData: Body_UpdateActivationWithMetadata) => {
        // @ts-ignore
        delete updateData.unitId;
        // @ts-ignore
        delete updateData.somSerial;
        // @ts-ignore
        delete updateData.tabletSerial;
        // @ts-ignore
        delete updateData.replacementTabletSerial;
        // @ts-ignore
        delete updateData.authType;
    }

    private renderRenamePopup() {
        const row = this.props.data.find(d => d.unitId === this.state.renameUnitId);
        if (!row)
            return

        const existActivationFormData = this.getActivationDataForRename(this.state.renameUnitId, row);

        return <div className={dialogWrapper}>
            <div style={{backgroundColor: "white"}}>
                <img width={12} height={12} style={{cursor: "pointer", marginTop: "10px", marginLeft: "10px"}}
                     alt={'delete'} src={require("@res/images/icon__x.svg")} onClick={() => {
                    DialogModule.close();
                    this.setState({renameUnitId: ""});
                }}/>
                <ActivationFormComponent renameUnitId={this.state.renameUnitId}
                                         defaultParams={existActivationFormData}
                                         userOnly={row.activated === undefined}
                                         onSubmit={(activationData, skipHistoryCheck) => {
                                             if (this.state.renameUnitId && activationData.unitId && activationData.somSerial && activationData.tabletSerial) {
                                                 return ActivationsModule.rename({...activationData, originalUnitId: this.state.renameUnitId, user_comment: this.state.renameReason}, skipHistoryCheck);
                                             }
                                         }}/>
            </div>
        </div>;
    }

    private getActivationDataForRename(renameUnitId: string, row: ActivationRow): ActivationDefaultParameters {
        const somSerial = row.som_serial;
        const tabletSerial = row.tablet_serial;
        if (!this.state.shouldKeepPrivateData) {
            return {
                unitId: renameUnitId,
                somSerial: somSerial,
                tabletSerial: tabletSerial
            };
        }

        let userGroup, subUserGroup;
        // const userGroup = UnitsModule.getUnitEnv(renameUnitId);
        UnitsModule.getUnitMetadata(renameUnitId)?.forEach(meta => {
            if (meta.dataKey === CONST_KS_UserGroup) {
                userGroup = meta.data;
            }
            if (meta.dataKey === CONST_KS_SubUserGroup) {
                subUserGroup = meta.data;
            }

        });

        const unitToAlias = PackageManagerModule.getUnit({unitId: renameUnitId, product: Elliq_ProductKey});
        const cId = unitToAlias?.aliasId || unitToAlias?.configId;
        let alias: Alias | undefined = undefined;
        if (cId)
            alias = PackageManagerModule.getAlias(cId);

        const patient = CareCenterModule.getUnitPatient(renameUnitId);
        const agentUser = ContactListModule.getItems().find(c => c.contactData.unitId === renameUnitId);
        return {
            unitId: renameUnitId,
            somSerial: somSerial,
            tabletSerial: tabletSerial,
            authType: row.authType,
            birthday: agentUser?.birthday, // Jan 1, 1970 (ms)
            firstName: row?.firstName,
            lastName: row?.lastName,
            gender: agentUser?.gender,
            middleName: agentUser?.middleName,
            phoneNumber: agentUser?.contactData.phoneNumber,
            areaCode: agentUser?.contactData.areaCode,
            email: agentUser?.contactData.email,
            userGroup,
            subUserGroup,
            country: agentUser?.address?.country,
            city: agentUser?.address?.city,
            state: agentUser?.address?.state,
            region: agentUser?.address?.region,
            streetAddress: agentUser?.address?.streetAddress,
            postalCode: agentUser?.address?.postalCode,
            extendedAddress: agentUser?.address?.extendedAddress,
            alias: alias?.id,
            aliasName: alias?.name,
            organizationId: patient?.organizationId,
            physicianId: patient?.physicianId,
            careManagerId: patient?.careManagerId
        }
    }

    private renderRow(rowValue: ActivationRow, idx: number) {
        const rowStyle = css({
            backgroundColor: "white",
            ':hover': {
                backgroundColor: "#F5F5F5"
            }
        });
        // const isSelected = this.isRowSelected(rowValue);
        return <tr key={idx} className={rowStyle} onClick={() => {
            if (!rowValue.som_serial && !rowValue.tablet_serial)
                return;

            RoutingModule.goToRoute(Route_UnitMonitoring, {
                product: Elliq_ProductKey,
                unitId: rowValue.unitId
            });
        }}>
            {this.headers.map((h, i) => this.renderCell(rowValue, idx, h, i))}
        </tr>;
    }

    private renderContactUpdatedAfterDelete = (rowValue: ActivationRow) => {
        if (!this.props.isHistory || !rowValue.contactUpdatedAfterUnitDelete)
            return null;

        return <div>
            <span
                className="switch"
                onMouseOver={(e) => {
                    showTooltip(e, "!! WARN: The contact was updated after this unit was deleted !!", -380);
                }}
                onMouseLeave={(e) => {
                    TooltipModule.hide();
                }}
            >
                <img width={20} height={20} src={icon__warning}/>
            </span>
        </div>
    }

    private renderActions(rowValue: ActivationRow, idx: number, cellIndex: number) {
        return <td
            key={idx + '_' + cellIndex}
            className={cellStyle}
        >
            <div className={`ll_h_c justify-even`}>
                {this.renderContactUpdatedAfterDelete(rowValue)}
                {!this.props.isHistory && this.renderEdit(rowValue)}
                {!this.props.isHistory && rowValue.activated !== undefined && this.renderUnRegister(rowValue)}
            </div>
        </td>;
    }

    private renderUnRegister(rowValue: ActivationRow) {
        const unitUserGroup = UnitsModule.getUnitUserGroup(rowValue.unitId);
        const isItProdUserGroup = unitUserGroup ? DataEnvsModule.get(unitUserGroup) : undefined;
        const permissionUrl = isItProdUserGroup?.type !== UserGroupTypes.IR_INTERNAL ? URL_ActivationsDelete : Url_AllPermitted;

        return <div style={{width: "25px"}}>
            <PermissionsComponent url={permissionUrl}>
                <div
                    style={{marginLeft: "5px"}}
                    className={'ll_v_c'}
                    onClick={(e) => {
                        e.stopPropagation();
                        const params = {
                            title: `What's the reason for deleting ${rowValue.unitId}?`,
                            bodyText: "",
                            ddlOptions: [disenrolledOption, cancelledOption],
                            ddlLabel: "Select reason (*)",
                            inputLabel: "Add free text", showInput: true,
                            ddlMandatoryText: "The select reason field is mandatory",
                            inputFieldIsMandatory: false,
                            ddlFieldIsMandatory: true,
                            showDetailedDdl: true,
                            onSubmit: (reason?: string) => {
                                const body: Body_DeleteActivation = {
                                    agent_id: rowValue.unitId,
                                    som_device_id: rowValue.som_serial as string,
                                    tablet_device_id: rowValue.tablet_serial as string,
                                    blame: getCurrentUserEmail()
                                };

                                if (reason)
                                    body.user_comment = reason;

                                ActivationsModule.unregister(body)
                                this.setState({loading: true});
                            },
                            submitButtonText: "Delete",
                            width: 600
                        };

                        return ConfirmationModule.confirmationDialog(params)
                    }}
                >
                    <img alt={'delete'} src={require("@res/images/icon__trash_modern.svg")}/>
                </div>
            </PermissionsComponent>
        </div>;
    }

    private renderCell(obj: ActivationRow, rowIndex: number, columnKey: keyof ActivationRow, cellIndex: number) {
        switch (columnKey) {
            case "actions":
                return this.renderActions(obj, rowIndex, cellIndex);
            case "activated":
                return <td key={rowIndex + '_' + cellIndex} className={`ll_h_c ${cellStyle}`} style={{height: 27}}>
                    {!this.props.isHistory && obj.activated !== undefined && this.renderChangeUnitId(obj)}
                    {this.renderActivated(obj)}
                </td>
        }

        const objElement = obj[columnKey];
        return <td key={rowIndex + '_' + cellIndex} className={` ${cellStyle}`}>
            <div className={'ll_h_c'}>
                <div style={{
                    maxWidth: `${Math.floor(110 / this.headers.length)}vw`,
                    overflow: "hidden",
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>{this.getCellValue(columnKey, objElement, obj)}</div>
                {objElement && ["unitId", "som_serial", 'tablet_serial'].find(key => key === columnKey) &&
                    <CopyButton obj={objElement}/>}
            </div>
        </td>;
    }


    private renderActivated = (obj: ActivationRow) => {
        if (obj.activated === undefined || this.props.isHistory)
            return "N/A"

        const actionName = obj.activated ? "De-Activate" : "Activate";

        return <div style={{width: "50px"}} onClick={(e) => {
            e.stopPropagation();
        }}>
            <label
                className="switch"
                onMouseOver={(e) => {
                    showTooltip(e, obj.activated ? "De-Activate" : "Activate");
                }}
                onMouseLeave={(e) => {
                    TooltipModule.hide();
                }}
            >
                <input
                    type="checkbox"
                    checked={!!obj.activated}
                    onChange={() => {
                        const params = {
                            title: `What's the reason for ${actionName.slice(0, -1).toLowerCase()}ing ${obj.unitId}?`,
                            bodyText: "",
                            showInput: true,
                            inputMandatoryText: "The reason field is mandatory",
                            inputFieldIsMandatory: true,
                            onSubmit: (reason?: string) => {
                                this.updateRegistration(obj, reason);
                            },
                            submitButtonText: actionName,
                            width: 600
                        };

                        return ConfirmationModule.confirmationDialog(params);
                    }}
                />
                <span className="slider round"></span>
            </label>
        </div>
    };

    private renderChangeUnitId = (obj: ActivationRow) => {
        if (!obj.activated)
            return <div
                style={{width: "28px", paddingTop: "3px"}}
                onMouseOver={(e) => {
                    showTooltip(e, "Rename unit ID");
                }}
                onMouseLeave={(e) => {
                    TooltipModule.hide();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    this.setState({askRename: obj.unitId});
                }}>
                <img alt={'edit'} src={require("@res/images/icon__recycling.svg")}/>
            </div>

        return <div style={{width: "28px"}}></div>
    };

    private renderEdit = (obj: ActivationRow) => {
        return <div
            style={{marginLeft: "3px", paddingTop: "1px"}}
            onMouseOver={(e) => {
                showTooltip(e, "Edit");
            }}
            onMouseLeave={(e) => {
                TooltipModule.hide();
            }}
            onClick={(e) => {
                e.stopPropagation();
                this.editItem(obj);
            }}>
            <img alt={'edit'} src={require("@res/images/icon__pencil_modern.svg")}/>
        </div>
    };

    private updateRegistration = (obj: ActivationRow, userComment?: string) => {
        const agentUser = ContactListModule.getItems().find(c => c.contactData.unitId === obj.unitId);
        const activation = ActivationsModule.getActivation(obj.unitId) as DBActivationsInterface;
        const body: DBActivationsInterface = {
            id: activation?.id,
            agent_id: obj.unitId,
            pincode: obj.pincode as string,
            som_device_id: obj.som_serial as string,
            tablet_device_id: obj.tablet_serial as string,
            replacement_tablet_device_id: obj.replacement_tablet_serial as string | null,
            activated: !obj.activated,
            auth_type: obj.authType,
            blame: getCurrentUserEmail(),
            som_pk: activation.som_pk,
            tablet_pk: activation.tablet_pk
        };
        ActivationsModule.update({...body, user_comment: userComment}, agentUser?.birthday)
        this.setState({loading: true});
    };

    private editItem = (obj: ActivationRow, assignSerial = false) => {
        this.setState({editAgentId: obj.unitId, assignSerial});
    };

    getDate = (stringDate?: string) => {
        if (!stringDate)
            return "";

        const date = !isNaN(Number(stringDate)) ? new Date(+stringDate) : new Date(stringDate);
        return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
    }

    getCellValue = (columnKey: keyof ActivationRow, objElement: any, row: ActivationRow) => {
        if (columnKey === "created" && objElement)
            return this.getDate(objElement.toString());

        if (columnKey === "authType") {
            if (objElement !== "AUTO" && row.pincode)
                return <ShowPincodeComp row={row} objElement={objElement}/>;

            return getAuthTypeText(objElement.toString());
        }

        if (columnKey === "auditBy" && typeof objElement === "string")
            return objElement.split("@")[0];

        if (row.activated === undefined && (columnKey === "som_serial" || columnKey === "tablet_serial"))
            return <button className={createNewBtnStyle} onClick={(e) => {
                e.stopPropagation();
                this.editItem(row, true);
            }}>Assign Serial</button>;

        return objElement;
    }
}

const ShowPincodeComp = ({row, objElement}: { row: ActivationRow, objElement: any }) => {
    const [showPincode, setShowPincode] = useState(false)
    const renderShowPincode = useCallback((rowValue: ActivationRow) => {
        return <div
            style={{marginLeft: "7px"}}
            className={'ll_v_c'}
            onMouseOver={(e) => {
                if (showPincode)
                    return showTooltip(e, "Hide pincode");

                showTooltip(e, "Show pincode");
            }}
            onMouseLeave={(e) => {
                TooltipModule.hide();
            }}>
            <img style={{padding: "5px"}} alt={'showPincode'}
                 src={showPincode ? require("@res/images/icon__eyeClose_modern.svg") : require("@res/images/icon__eye_modern.svg")}/>
        </div>
    }, [showPincode])

    if (showPincode)
        return <div className={"ll_h_c"} onClick={(e) => {
            e.stopPropagation();
            setShowPincode(!showPincode)
        }}>
            {row.pincode}{renderShowPincode(row)} {/*render close eyes icon*/}
        </div>;

    return <div className={"ll_h_c"} onClick={(e) => {
        e.stopPropagation();
        setShowPincode(!showPincode)
    }}>
        {getAuthTypeText(objElement?.toString())}{renderShowPincode(row)}
    </div>;

}
