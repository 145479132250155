import * as React from "react";
import {BaseComponent, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import './style.css';
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {RequestKey_GetUnitStatusTable} from "@modules/UnitsModule";
import {BQDataPlans, BQDataFacts, BQDataPlan} from "@app-sp/app-shared/api";
import {LoadableComponent} from "@components/LoadableComponent";
import {QueryParam_UnitId} from "@consts/common";
import {css} from "emotion";
import {ObjectTS} from "@intuitionrobotics/ts-common/utils/types";

interface State {
    label: string;
    selectedDay: number;
    selectedMonth: number;
    selectedYear: number;
    agentId: string;
    plansList: BQDataPlan[];
    factsList: ObjectTS[];
    selectedPlanRootExecutionId: string|null;
    showAllFacts: boolean;
    rawDisplay: boolean;
    isFieldSelectorDropDownOpen: boolean;
    selectedFields: string[];
}

type Props = { hidden?: boolean };

const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017];
const months:string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const daysInMonth:number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const bqFields: string[] = ["id", "creator", "factType", "factSource", "planName", "planType", "rootExecutionId", "originator", "timeStamp", "timeZone", "appVersion", "contentVersion", "planResult", "planOutcome", "sectionName", "stepNumber", "totalStepsNumber", "carePlanElement", "initiatingEventType", "runningTime", "status", "actionType", "sequenceName", "name", "unitName", "parentActionName", "parentActionExecutionId", "stepName", "stepStatus", "testResult", "expressionSelectionMode", "chosenConvinceType", "consideredConvinceTypes", "eventType", "recording", "recordingCause", "recordingId", "eventId", "notificationId", "type", "originatorEventCreationTime", "keywordSource", "speakerDirectionDsp", "sessionId", "originalText", "text", "localTextToSpeech", "intent", "sentiment", "sentimentMagnitude", "parameters", "confidence", "context", "service", "intentOriginator", "sourceData", "remoteId", "nlpContext", "contextSwitchHappened", "intents", "talkStartTime", "textSpoken", "textToSpeak", "reminderStatus", "anyInformationExists", "relevantDataExists", "patternModelOutcomeValue", "addedSuccessfully", "event", "journeyType", "journeyId", "isErrorSpeechTimeout", "isSignalLost", "isFeedbackListener", "maxAbsSum", "minAbsSum", "avgAbsSum", "varAbsSum", "listenDuration", "speechStart", "speechEnd", "partDate", "clientType", "lastAgentOutput", "stopConditionIntentsContext", "validIntentsContext", "latestAcceptedVersion", "latestAcceptedVersionTimestamp", "latestRejectedVersion", "latestRejectedVersionTimestamp"];
const bqFieldsMandatory: string[] =  ["factType", "originalText", "intent", "textSpoken" ];
const userTalkCellStyle = css(`
        background-color: #add8e6;
    `);

const unitTalkCellStyle = css(`
        background-color: #FFA07A;
    `);

export class Page_Unit_Facts
    extends BaseComponent<Props, State> {

    constructor(props: any) {
        super(props);

        const date:Date = new Date();
        const agentId:string = LoadableComponent.getQueryParameter(QueryParam_UnitId) ?? '';

        this.state = {
            label: "GPT Conversation tool",
            selectedDay: date.getDate(),
            selectedMonth: date.getMonth(),
            selectedYear: date.getFullYear(),
            agentId: agentId,
            plansList: [],
            factsList: [],
            selectedPlanRootExecutionId: null,
            showAllFacts: false,
            rawDisplay: false,
            isFieldSelectorDropDownOpen: false,
            selectedFields: [...bqFieldsMandatory],
        };

        this.loadPlans = this.loadPlans.bind(this);
        this.handlePlanClick = this.handlePlanClick.bind(this);
        this.getClassByData = this.getClassByData.bind(this);
        this.getSelectedDate = this.getSelectedDate.bind(this);
        this.toggleShowAllFacts = this.toggleShowAllFacts.bind(this);
        this.toggleShowRaw = this.toggleShowRaw.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    getFactBackgroundColor(fact: ObjectTS): string {
        if (fact["factType"] == "WAKEWORD_EVENT" || fact["factType"] == "INTENT" || fact["factType"] == "KNOB_EVENT")
            return userTalkCellStyle;
        return unitTalkCellStyle;
    }

    getFactRepresentation(fact: ObjectTS): string {

        if (this.state.rawDisplay)
            return JSON.stringify(fact);

        var ret: string = "";
        switch (fact["factType"]) {
            case "KNOB_EVENT":
                ret = "knob clicked";
                break;
            case "WAKEWORD_EVENT":
                ret = "wakeword";
                break;
            case "INTENT":
                if (fact.originalText != null)
                    ret = fact["originalText"];
                else
                    ret = fact["intent"];
                break;
            case "TTS":
                ret = fact["textSpoken"];
                break;
            case "PLAN_TERMINATED":
                ret = "plan ended"
                break;
            default:
                ret = this.state.showAllFacts ? JSON.stringify(fact) : "";
        }

        return ret;
    }

    handlePlanClick(rootExecutionId: string) {
        this.setState({selectedPlanRootExecutionId: rootExecutionId});

        // TODO: send the rootExecution id becuase setState is asynchronous and may have not
        // been updated when loadFacts() is called. this is, of course, no excuse and needs to be resolved
        this.loadFacts(rootExecutionId);
    }

    getClassByData(data: ObjectTS) {
        if (data["rootExecutionId"] == this.state.selectedPlanRootExecutionId)
            return "td-plan-selected";
        return "td-plan";
    }

    getFormattedTimeString(timestamp:number): string {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
    }

    renderPlansList() {
        return <table>
            <tbody>
            {this.state.plansList.map((row) => (
                <tr key={row.rootExecutionId}>
                    <td key={row.rootExecutionId}
                        onClick={() => this.handlePlanClick(row.rootExecutionId)}
                        className={this.getClassByData(row)}>
                        {this.getFormattedTimeString(row["timestamp"])}: {row["planName"]}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    }

    renderRootExecutionIdHeader() {
        if (this.state.selectedPlanRootExecutionId == null || this.state.factsList.length == 0)
            return "";

        var planData:ObjectTS|null = null;
        for (var i:number = 0; i < this.state.plansList.length; i++) {
            if (this.state.plansList[i].rootExecutionId == this.state.selectedPlanRootExecutionId) {
                planData = this.state.plansList[i];
                break;
            }
        }

        return <div>
            <div>plan name: <b>{planData?planData["planName"]:""}</b></div>
            <div>rootExecutionId: <b>{planData?planData["rootExecutionId"]:""}</b></div>
            <div>started: <b>{this.getFormattedTimeString(planData?planData["timestamp"]:0)} </b></div>
        </div>;
    }

    renderFactsList() {
        if (this.state.selectedPlanRootExecutionId == null)
            return "";
        return <table>
            <thead>
            <tr>
                <td colSpan={2}>{this.renderRootExecutionIdHeader()}</td>
            </tr>
            </thead>
            <tbody>
                <tr><td>
                    {this.renderFactsTable()}
                </td></tr>
            </tbody>
        </table>
    }


    getFormattedDate():string {
        const paddedMonth = String(this.state.selectedMonth+1).padStart(2, '0');
        const paddedDay = String(this.state.selectedDay).padStart(2, '0');

        return `${this.state.selectedYear}-${paddedMonth}-${paddedDay}`;
    }

    loadPlans(){
        this.setState({factsList: []});
        XhrHttpModule
            .createRequest(HttpMethod.GET, RequestKey_GetUnitStatusTable)
            .setRelativeUrl(`/v1/bq/get-plans`)
            .setLabel(`Fetching plans`)
            .setOnError(`Error Fetching plans`)
            .setUrlParams({agentId: this.state.agentId, date: this.getFormattedDate()})
            .execute(async response => {
                const dataResults: BQDataPlans = response as BQDataPlans;
                this.setState({plansList: dataResults.data});
            });
    }

    loadFacts(rootExecutionId: string) {
        XhrHttpModule
            .createRequest(HttpMethod.GET, RequestKey_GetUnitStatusTable)
            .setRelativeUrl(`/v1/bq/get-facts`)
            .setLabel(`Fetching facts`)
            .setOnError(`Error Fetching facts`)
            .setUrlParams({rootExecutionId: rootExecutionId, date: this.getFormattedDate(), fieldList: this.state.selectedFields.join(',')})
            .execute(async response => {
                const dataResults: BQDataFacts = response as BQDataFacts;
                this.setState({factsList: dataResults.data});
            });
    }

    getSelectedDate():Date {
        return new Date();
    }

    renderFactsTable() {
        if (this.state.factsList.length == 0)
            return "";
        if (this.state.rawDisplay) {
            const fieldNames: string[] = Object.keys(this.state.factsList[0]);
            return <table>
                <thead>
                <tr>
                    {fieldNames.map((fieldName) => (
                        <th key={fieldName} style={{padding: '8px', textAlign: 'left'}}>
                            {fieldName}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {
                    this.state.factsList.map((obj) => (
                        <tr>
                            {fieldNames.map((fieldName: string) => (
                                <td
                                    className={this.getFactBackgroundColor(obj)}>
                                    {obj[fieldName]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        }

        return <table>
            <tbody>
            {this.state.factsList.map((row, index) => (
                <tr key={index}>
                    <td key={index}
                        className={this.getFactBackgroundColor(row)}>
                        {this.getFactRepresentation(row)}
                    </td>
                </tr>

            ))};
            </tbody>
        </table>;

    }

    renderDateDropDowns() {
        var daysArray: number[] = [];
        for (let i: number = 1; i <= daysInMonth[this.state.selectedMonth]; i++)
            daysArray.push(i);

        return <div>
            <select value={+this.state.selectedDay} onChange={(event) => {this.setState({selectedDay: +event.target.value})}}>
                {daysArray.map((day) => (
                    <option key={day} value={day}>
                        {day}
                    </option>
                ))}
            </select>
            <select value={+this.state.selectedMonth} onChange={(event) => {this.setState({selectedMonth: +event.target.value})}}>
                {months.map((month, index) => (
                    <option key={index} value={index}>
                        {month}
                    </option>
                ))}
            </select>
            <select value={+this.state.selectedYear} onChange={(event) => {this.setState({selectedYear: +event.target.value})}}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>;
    }

    renderFactsPerPlanList() {
        return <div>{this.state.selectedPlanRootExecutionId}</div>;
    }

    toggleShowAllFacts() {
        this.setState((prevState:State) => ({showAllFacts: !prevState.showAllFacts}));
    }

    toggleShowRaw() {
        this.setState((prevState:State) => ({rawDisplay: !prevState.rawDisplay}));
    }

    componentDidUpdate() {
        const agentId:string = LoadableComponent.getQueryParameter(QueryParam_UnitId) ?? '';
        if (this.state.agentId !== agentId) {
            this.setState({
                agentId: agentId,
                plansList: [],
                factsList: [],
                selectedPlanRootExecutionId: null});
        }
    }

    handleCheckboxChange(option:string) {
        // can't change mandatory fields
        if (bqFieldsMandatory.includes(option))
            return;

        var tmpArray:string[] = this.state.selectedFields;

        if (this.state.selectedFields.includes(option))
            tmpArray = tmpArray.filter(item => item !== option);
        else
            tmpArray.push(option);
        this.setState({selectedFields: tmpArray});
    };

    toggleDropdown() {
        this.setState((prevState:State) => ({isFieldSelectorDropDownOpen: !prevState.isFieldSelectorDropDownOpen}));
    }

    renderFieldSelectorDropDown() {
        return <div>
            <button onClick={this.toggleDropdown}>Select Fields</button>
            {this.state.isFieldSelectorDropDownOpen && (
                <div className="dropdown-menu">
                    {bqFields.map((option) => (
                        <div key={option}>
                            <input
                                type="checkbox"
                                id={option}
                                value={option}
                                checked={this.state.selectedFields.includes(option)}
                                onChange={() => this.handleCheckboxChange(option)}
                            />
                            <label htmlFor={option}>{option}{bqFieldsMandatory.includes(option)?"(Mandatory)":""}</label>
                        </div>
                    ))}
                </div>
            )}
        </div>;
    }

    render() {
        return <div style={{visibility: this.props.hidden === true ? 'hidden' : 'visible', width: '90%', height: '90%', overflow: 'auto', border: '0px solid #000'}}
                    className={"ll_v_c match_width match_height"}>

            <div className="form-container">
                <label>Show all: </label>
                <input type="checkbox" checked={this.state.showAllFacts} onChange={this.toggleShowAllFacts}/>
            </div>

            <div className="form-container">
                <label>Raw data: </label>
                <input type="checkbox" checked={this.state.rawDisplay} onChange={this.toggleShowRaw}/>
            </div>

            {this.renderFieldSelectorDropDown()}
            {this.renderDateDropDowns()}
            <button onClick={this.loadPlans}>Load Plans</button>
            <table>
                <tr>
                    <td className="td-base">{this.renderPlansList()}</td>
                    <td className="td-base">{this.renderFactsList()}</td>
                </tr>
            </table>
        </div>;
    }
}