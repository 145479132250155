import {Minute, Module} from "@intuitionrobotics/ts-common";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Api_GetDataForActivations, DocData, OrgData, PatientWithId} from "@app/ir-q-app-common/types/care-center/api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";

export const RequestKey_FetchCCData = "fetch-cc-data";

type Config = {
    websiteUrl: string
    commandsUrl: string
}

class CareCenterModule_Class
    extends Module<Config> {

    private organizations?: OrgData[]
    private patients?: PatientWithId[]
    private physicians?: DocData[]
    private careManagers?: DocData[]

    private updated = 0;

    private readonly addPatientUrl = "add-patient";

    getWebsiteUrl = () => this.config.websiteUrl;
    getCommandsUrl = () => this.config.commandsUrl;
    getAddPatientUrl = () => this.addPatientUrl;

    fetchCCData = () => {
        if (this.updated > Date.now() - Minute) {
            this.logInfo("Skipping fetch CC data, updated at " + new Date(this.updated).toLocaleString())
            return;
        }
        XhrHttpModule.createRequest<Api_GetDataForActivations>(HttpMethod.GET, RequestKey_FetchCCData)
            .setRelativeUrl("/v1/activations-data/get")
            .setOnError("Failed to fetch CC data")
            .execute(response => {
                this.organizations = response.organizations;
                this.patients = response.patients;
                this.careManagers = response.careManagers;
                this.physicians = response.physicians;
                this.updated = Date.now();
            })
    };

    getOrganizations = () => this.organizations;

    getPatients = () => this.patients;

    getUnitPatient(unitId: string) {
        return this.patients?.find(p => p.unitId === unitId)
    }

    getPhysicians = () => this.physicians;
    getCareManagers = () => this.careManagers;
}

export const CareCenterModule = new CareCenterModule_Class("CareCenterModule")
