import * as React from "react";
import {useContext} from "react";
import {css} from "emotion";
import {BaseComponent, Dialog_Builder, RoutingModule, ToastModule, TS_Checkbox} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
    DeviceView,
    OnDeviceUpair,
    RequestKey_FetchUnitsFullList,
    RequestKey_FetchUnitsList,
    RequestKey_FetchUnitsOnboardingTimes,
    RequestKey_QueryUnitMetadata,
    RequestKey_UpdateUnitMetadata,
    UnitsModule,
    UnitView
} from "@modules/UnitsModule";
import {hideTooltip, showTooltip} from "@renderers/unit-info";
import {COLOR_lightBlue, COLOR_lightGreen, COLOR_lightOrange, COLOR_lightRed, COLOR_lightYellow, COLOR_purple, COLOR_white} from "@styles/colors";
import {_keys, arrayToMap, currentTimeMillies, Day, deepClone, filterDuplicates, Hour, merge, Minute, Second} from "@intuitionrobotics/ts-common";
import {Route_UnitMonitoring, Url_SupportTeam} from "../../routes";
import {createFilterPattern, createUnitErrorRegexFilter, SortOrder} from "@components/table/consts";
import {closeButton} from "@components/AppDialog";
import {Dropdown_BatchActions} from "./components/Dropdown_BatchActions";
import {DeviceIdentity, DeviceType, UnitBasic, UserGroupTypes} from "@app/ir-q-app-common/types/units";
import {isAlive} from "@app/ir-q-app-common/types/runtime-status";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {OnConfigsListFailed, PackageManagerModule, RequestKey_FetchAliases, RequestKey_FetchConfigs, RequestKey_FetchUnits} from "@modules/package-manager/PackageManagerModule";
import {SimpleLoader} from "@components/SimpleLoader";
import {blinking} from "../../components/BlinkingButton";
import Component_GeneralComment from "./components/Component_GeneralComment";
import {CONST_KS_UserGroup, CONST_SP_Comment} from "@app/ir-q-app-common/types/unit-config";
import {AndroidArtifactData, InstallationState, PmStatus} from "@app-sp/app-shared/package-manager";
import {UiEnvItem} from "@modules/DataEnvsModule";
import {FirebaseListenerModule, OnVisibilityChange} from "@modules/FirebaseListenerModule";
import {AppPackage_ElliQ, AppPackage_TabletElliQ} from "@app/ir-q-app-common/types/push-messages";
import {
    errorDiffVersion,
    errorNoRuntimeStatus,
    getAliasAndConfig,
    icon__leftArrow,
    icon__pencil,
    icon__redPencil,
    icon__rightArrow,
    MyTableRow,
    PageUnitsState,
    Status,
    tableHeaderConfigInit,
    UnitIdentitiesType,
    VersionStatus
} from "./utils";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {ActivationsModule} from "@modules/ActivationsModule";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {UnitsTableHead} from "./UnitsTableHead";
import {UnitContext, UserGroupsContext} from "../../App";
import {NewStyledDropDown} from "@components/NewStyledDropDown";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {chooseUnitsOptions} from "../pairing/Page_Admin";
import {Numbers_Table} from "./Numbers_Table";

const cellStyle = css({padding: 0});
const SessionKey_State = 'page_units_state';
const key_update = 'key-update';

const sortable = [
    "unitId",
    "version",
    "buildNumber",
    "tabletTime",
    "somTime",
    "alive",
    "aliasConfig",
    "whoUpdated"
]


const initStatusFilters = () => {
    return {
        "live": false,
        "category": false,
        "error": false,
        "tabletOff": false,
        "somOff": false,
        "down": false,
        "manuallyOff": false,
        "preInstall": false,
        "total": false
    }
};

const initUserGroupFilters = (envs: UiEnvItem[]) => {
    const filterEnvs: { [key: string]: boolean } = {}
    envs.forEach(env => {
        if (env.type !== initUnitsType())
            return;

        filterEnvs[env.label] = false;
    })
    return filterEnvs;
}

export const initUnitsType = () => EnvironmentModule.isProd() ? UserGroupTypes.USERS : UserGroupTypes.IR_INTERNAL;

const initFilters = (envs: UiEnvItem[]) => ({
    selectedDateRange: {},
    statusFilters: initStatusFilters(),
    userGroupFilters: initUserGroupFilters(envs),
    filters: {
        unitId: "",
        unitIdentity: "",
        version: "",
        buildNumber: "",
        versionStatus: "",
        memory: "",
        handshake: "",
        alive: "",
        installation: "",
        isRc: "",
        userGroup: "",
        alias: "",
        systemErrors: "",
        whoPaired: "",
        filesystemMemoryUsed: "",
        deviceVersion: ""
    },
    batch: false,
    batchUnits: [],
    checkAll: false,
    tableHeaderConfig: [...tableHeaderConfigInit]
});

export const getVersionStatus = (unit?: Partial<UnitView>) => {
    if (!unit)
        return VersionStatus.noVersions;

    const {som, tablet} = unit;
    if (!som?.version && !tablet?.version)
        return VersionStatus.noVersions

    if (!som?.version)
        return VersionStatus.noSomVersion

    if (!tablet?.version)
        return VersionStatus.noTabletVersion

    const somVersion = som.version.split("R")[0];
    const tabletVersion = tablet.version.split("R")[0];

    return somVersion === tabletVersion ? VersionStatus.sameVersions : VersionStatus.differentVersions;
}

export const isDiffVersionMoreThan30Min = (unitId: string, unitView?: Partial<UnitView>) => {
    const pmStatuses = UnitsModule.getPmStatus(unitId);
    if (!pmStatuses || !pmStatuses.length)
        return false;

    let elliqApp: AndroidArtifactData | undefined;
    let rsApp: AndroidArtifactData | undefined;

    pmStatuses.forEach(pmStatus => {
        const findElliqApp = pmStatus.status.artifact_data?.find(artifact => artifact.packageName === AppPackage_ElliQ);
        const findRsApp = pmStatus.status.artifact_data?.find(artifact => artifact.packageName === AppPackage_TabletElliQ);
        if (findElliqApp)
            elliqApp = findElliqApp;

        if (findRsApp)
            rsApp = findRsApp;
    });

    const thirtyMinAgo = currentTimeMillies() - (30 * Minute);
    if (elliqApp && rsApp
        && elliqApp.installationState === InstallationState.InstalledUpToDate && elliqApp.installationState === InstallationState.InstalledUpToDate
        && elliqApp.installationTime < thirtyMinAgo && rsApp.installationTime < thirtyMinAgo
    )
        return getVersionStatus(unitView) === VersionStatus.differentVersions;

    return false;
}

export const isDeviceRuntimeStatusExist = (deviceView: Partial<DeviceView> | undefined) => {
    return _keys(deviceView || {}).length > 3; // bc one or two fields written by external.
}

export const prepareStatusValues = (unitId: string, unitView: UnitView): Status => {
    const somInfo = unitView?.som;
    const tabletInfo = unitView?.tablet;
    const unitIsNotUp = !isDeviceRuntimeStatusExist(somInfo) || !isDeviceRuntimeStatusExist(tabletInfo);
    if (ActivationsModule.getActivation(unitId)?.activated && unitIsNotUp)
        return Status.error;

    if (!somInfo && !tabletInfo) {
        return Status.preInstall;
    }

    const isSomAlive = isAlive(somInfo?.timestamp);
    if (!isSomAlive && somInfo?.manualShutdown)
        return Status.manuallyOff;

    const isTabletAlive = isAlive(tabletInfo?.timestamp);
    if (!isSomAlive && !isTabletAlive)
        return Status.down;

    if (!isSomAlive)
        return Status.somOff

    if (!isTabletAlive)
        return Status.tabletOff

    const somOnError = !!unitView.som?.errorCodes;
    const tabletOnError = !!unitView.tablet?.errorCodes;
    if (!somInfo?.manualShutdown && isSomAlive && isTabletAlive && (somOnError || tabletOnError))
        return Status.error;

    if (isDiffVersionMoreThan30Min(unitId, unitView))
        return Status.error;

    return Status.live
};

export const handleUnitsTypeFilter = (unitId: string, consideredEnvs: string[]) => {
    const pairComment = UnitsModule.getUnitUserGroup(unitId);
    if (!pairComment || !consideredEnvs.includes(pairComment)) {
        return false;
    }

    return true;
}

class Page_Units
    extends BaseComponent<{ envs: UiEnvItem[] }, PageUnitsState>
    implements OnDeviceUpair, OnRequestListener, OnConfigsListFailed, OnVisibilityChange {
    private itemPerPage: number = 50;

    constructor(props: any) {
        super(props);
        this.state = merge({
            sortBy: "alive",
            loading: false,
            page: 1,
            sortOrder: SortOrder.ASC,
            unitsType: initUnitsType()
        }, this.initFiltersHelper());
    }

    initFiltersHelper = () => initFilters(this.props.envs);

    initState() {
        const item = sessionStorage.getItem(SessionKey_State);
        if (item) {
            try {
                const stateAndTime = JSON.parse(item);
                const state = this.validateStateAndTime(stateAndTime);
                if (state) {
                    ToastModule.toastSuccess("Restored filters...")
                    this.setState(state);
                }
            } catch (e) {
                this.logError('failed to parse state')
            }
        }
    }

    private fetchUnits = () => {
        this.setState({loading: true});
        UnitsModule.cancelFetching();
        this.startListen();
        UnitsModule.fetchOnboardingTimesImpl(/*30 * Second*/);
        PackageManagerModule.fetchUnits();
        PackageManagerModule.fetchAliases();
        PackageManagerModule.fetchConfigs("elliq");
        UnitsModule.queryUnitMetadata({dataKey: {$in: [CONST_SP_Comment, CONST_KS_UserGroup]}});
        PackageManagerModule.fetchAppsList();
    };

    private startListen = () => {
        FirebaseListenerModule.listenImplForRSView(() => {
            this.updateUi();
        });
        FirebaseListenerModule.listenImplForPM(() => {
            this.updateUi();
        });
    };

    private updateUi = () => {
        this.throttle(() => this.forceUpdate(), key_update, 2 * Second)
    };

    __onVisibilityChange(visibilityState: DocumentVisibilityState) {
        switch (visibilityState) {
            case "hidden":
                this.stopListening();
                break;
            case "visible":
                this.startListen();
                break;
            default:
                console.warn(`Something is wrong, visibility state should be hidden or visible but is ${visibilityState}`)
        }
    }

    componentDidMount(): void {
        this.initState();
        this.fetchUnits();
    }

    componentWillUnmount() {
        this.stopListening();
        UnitsModule.cancelFetching();
    }

    private stopListening = () => {
        FirebaseListenerModule.stopListening();
    };

    __onConfigsListError(message: string): void {
        ToastModule.toastError(message);
    }

    __onConfigsListPermissionsRequired(): void {
    }

    __onRequestCompleted = (key: string) => {
        switch (key) {
            case RequestKey_FetchAliases:
            case RequestKey_FetchConfigs:
            case RequestKey_FetchUnitsOnboardingTimes:
            case RequestKey_QueryUnitMetadata:
            case RequestKey_UpdateUnitMetadata:
                return this.updateUi();
            case RequestKey_FetchUnits:
            case RequestKey_FetchUnitsList:
            case RequestKey_FetchUnitsFullList:
                return this.setState({loading: false});
            default:
                return;
        }
    };

    onDeviceUnpair(): void {
        this.updateUi();
    }

    onUnpairFailed(): void {
        this.setState({loading: false});
    }

    onFilterNewValueChange = (val: string, id: string) => {
        const rawFilter = val?.toLowerCase() || '';
        const filterId = id;
        this.setState((prev) => ({
            filters: {
                ...prev.filters,
                [filterId]: rawFilter
            },
            checkAll: false
        }), this.persistState);
    };

    onStatusClick = (column: keyof MyTableRow) => {
        this.setState(prev => {
            prev.statusFilters[column] = !prev.statusFilters[column];
            return {statusFilters: prev.statusFilters};
        }, this.persistState);
    }

    onUserGroupClick = (env: string) => {
        this.setState(prev => {
            prev.userGroupFilters[env] = !prev.userGroupFilters[env];
            return {userGroupFilters: prev.userGroupFilters};
        }, this.persistState);
    }

    clickedOnCell = (env: string, monitoringKey: string) => {
        this.setState(prev => {

            if (env !== "Total" && prev.statusFilters[monitoringKey] && !prev.userGroupFilters[env]) {
                prev.userGroupFilters[env] = !prev.userGroupFilters[env];
            } else if (prev.userGroupFilters[env] && !prev.statusFilters[monitoringKey]) {
                prev.statusFilters[monitoringKey] = !prev.statusFilters[monitoringKey];
            } else {
                prev.statusFilters[monitoringKey] = !prev.statusFilters[monitoringKey];

                if (env !== "Total")
                    prev.userGroupFilters[env] = !prev.userGroupFilters[env];
            }

            return {statusFilters: prev.statusFilters, userGroupFilters: prev.userGroupFilters};
        }, this.persistState);
    }

    private persistState = () => {
        const value = {state: this.state, time: currentTimeMillies()};
        const state = JSON.stringify(value);
        sessionStorage.setItem(SessionKey_State, state);
    };

    private getConsideredEnvs = () => {
        return this.props.envs.filter(env => this.state.unitsType === env.type).map(env => env.label);
    };

    onSortClick = (sortBy: string) => {
        if (!sortable.includes(sortBy))
            return;

        let newOrder = this.state.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        if (this.state.sortBy !== sortBy)
            newOrder = SortOrder.ASC;

        this.setState(
            {
                sortBy: sortBy,
                sortOrder: newOrder
            },
            this.persistState
        );
    };

    getSortedIds = (elements: string[]) => {
        const fn = (unitId: string) => {
            const sortBy = this.state.sortBy;
            const item = UnitsModule.getUnitView(unitId);
            const activation = ActivationsModule.getActivation(unitId);
            const whoPaired = activation ? activation.blame : UnitsModule.getIdentity(unitId)?._audit?.auditBy || "";
            const som = item?.som;
            const somTime = som?.timestamp;
            const tablet = item?.tablet;
            const timestamp = tablet?.timestamp;
            switch (sortBy) {
                case "unitId":
                    return unitId;
                case "version":
                    return this.getVersion(som?.version) || "";
                case "buildNumber":
                    return this.getBuildNumber(som?.version) || "";
                case "isRc":
                    return (UnitsModule.getPmStatus(unitId) || []).reduce((toRet, item) => {
                        if (item.isRC)
                            toRet += .5;

                        return toRet;
                    }, 0);
                case "tabletTime":
                    return timestamp || 0;
                case "somTime":
                    return somTime || 0;
                case "alive":
                    return (isAlive(somTime) ? .5 : 0) + (isAlive(timestamp) ? .5 : 0);
                case "aliasConfig":
                    const {alias, config} = getAliasAndConfig(unitId);
                    return alias ? alias : config;
                case "whoUpdated":
                    return whoPaired;
                default:
                    // ToastModule.toastError(`No such type: ${sortBy}`);
                    return unitId;
            }
        }

        return elements.sort((a, b) => (fn(a) > fn(b) ? -1 : 1) * (this.state.sortOrder === SortOrder.ASC ? 1 : -1));
    };

    private rowColor = (unitId: string, unitView: UnitView) => {
        const status = prepareStatusValues(unitId, unitView);
        switch (status) {
            case Status.live:
                return COLOR_lightGreen;
            case Status.error:
                return COLOR_lightOrange;
            case Status.tabletOff:
            case Status.somOff:
                return COLOR_lightYellow;
            case Status.down:
                return COLOR_lightRed;
            case Status.manuallyOff:
                return COLOR_purple;
            case Status.preInstall:
                return COLOR_lightBlue;
            default:
                return COLOR_white;
        }
    };

    private renderPagination = (content: string[]) => {
        const pagination = this.calculatePagination();

        const ceil = pagination ? Math.ceil(content.length / (pagination.endIndex / this.state.page)) : 0;
        return <div style={{display: "flex", marginTop: 10}}>
            <img alt={"clickable"} src={icon__leftArrow} width={22} className={"ll_h_c clickable"}
                 style={{
                     marginLeft: -5,
                     marginRight: 5,
                     color: this.state.page > 1 ? "#566270" : "rgba(131, 146, 166, 1)"
                 }} onClick={() => {
                if (this.state.page > 1)
                    this.setState(state => ({page: state.page - 1}));
            }}/>
            <img alt={"clickable"} src={icon__rightArrow} width={22} className={"ll_h_c clickable"}
                 style={{color: this.state.page <= ceil ? "#566270" : "rgba(131, 146, 166, 1)"}} onClick={() => {
                if (this.state.page < ceil)
                    this.setState(state => ({page: state.page + 1}));
            }}/>
            <div style={{width: 16, pointerEvents: 'none'}}/>
            {
                pagination && <div style={{width: 200, display: "flex", alignSelf: "center"}}>
                    {`Showing ${this.state.page} of ${ceil || 1} ${Math.ceil(
                        content.length / (pagination.endIndex / this.state.page)) > 1 ? "pages" : "page"}`}
                </div>
            }
        </div>
    };
    calculatePagination = () => {
        const startIndex = (this.state.page - 1) * this.itemPerPage;
        const endIndex = startIndex + this.itemPerPage;
        return {startIndex, endIndex};
    };

    render() {
        return <UnitContext.Consumer>
            {units => {
                const {activatedUnits, pairedUnits} = units;
                if (activatedUnits.size + pairedUnits.size === 0)
                    return <SimpleLoader overlay={true}/>;

                const allUnits = filterDuplicates([...Array.from(pairedUnits), ...Array.from(activatedUnits)])

                const filteredUnits = this.prepareFilteredUnits(allUnits);

                const sortedFilteredUnits = this.getSortedIds(filteredUnits);

                const pagination = this.calculatePagination();
                const slicedTableContent: string[] = sortedFilteredUnits.slice(pagination.startIndex, pagination.endIndex);

                return <div className="match_width stacking page-units-wrapper">
                    <div className="stacking match_width" style={{background: "#fff"}}>
                        {this.renderTopTable(sortedFilteredUnits, allUnits)}
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            {this.renderHeader(filteredUnits, this.props.envs)}
                            <TableBody>
                                {this.getTableBody(slicedTableContent)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.renderPagination(filteredUnits)}
                    {this.state.loading && <SimpleLoader overlay={true}/>}
                </div>
            }}
        </UnitContext.Consumer>
    }

    private renderHeader(filteredUnits: string[], envs: UiEnvItem[]) {
        // const Memo = React.memo(UnitsTableHead);
        return <UnitsTableHead
            filters={this.state.filters}
            onFilterNewValueChange={this.onFilterNewValueChange}
            onDateRangeChange={(id, selectedDateRange) => {
                this.setState(state => {
                    const oldDateRange = {...state.selectedDateRange};
                    oldDateRange[id] = selectedDateRange;
                    return {...state, selectedDateRange: oldDateRange}
                }, this.persistState)
            }}
            frozen={!!this.state.selectedFrozen}
            onFrozenSelected={() => {
                this.setState(s => {
                    return {...s, selectedFrozen: s.selectedFrozen ? undefined : filteredUnits}
                }, this.persistState)
            }}
            selectedDateRange={this.state.selectedDateRange}
            sortBy={this.state.sortBy}
            sortOrder={this.state.sortOrder}
            onSortClick={this.onSortClick}
            tableHeaderConfig={this.state.tableHeaderConfig}
            onTableHeaderConfigChange={list => this.setState({tableHeaderConfig: list}, this.persistState)}
            envs={envs}
            checkAll={this.state.checkAll}
            batch={this.state.batch}
            onCheckAll={() => {
                this.setState(prev => {
                    const batchUnits: string[] = [];
                    if (!prev.checkAll)
                        filteredUnits.forEach(unit => batchUnits.push(unit));
                    return {batchUnits, checkAll: !prev.checkAll};
                });
            }}
        />
    }

    private prepareFilteredUnits(allUnits: string[]): string[] {
        if (this.state.selectedFrozen)
            return this.state.selectedFrozen;

        const {filters} = this.state;

        return allUnits.filter((unitId: string) => {
            const pairBasic = UnitsManagerModule.getUnitBasic(unitId);
            if (!pairBasic)
                return false;

            const unit = UnitsModule.getUnitView(unitId);

            const somAlive = isAlive(unit?.som?.timestamp);
            const tabletAlive = isAlive(unit?.tablet?.timestamp);

            let uid = true;
            if (filters.unitId) {
                uid = createFilterPattern(filters.unitId).test(unitId);
                if (filters.unitId[0] === "!")
                    uid = !uid;
            }

            const whoPaired = this.getBlame(unitId);
            let wP = true;
            if (filters.whoPaired) {
                wP = createFilterPattern(filters.whoPaired).test(whoPaired);
                if (filters.whoPaired[0] === "!")
                    wP = !wP;
            }

            const mu = this.handleDiskMemoryInUseFilter(pairBasic);
            const vf = this.applyVersionFilter(unit);

            const versionStatus = getVersionStatus(unit);
            let vsf = true;
            if (filters.versionStatus) {
                vsf = createFilterPattern(filters.versionStatus).test(versionStatus);
                if (filters.versionStatus[0] === "!")
                    vsf = !vsf;
            }

            const systemErrors = (this.getUnitErrors(unitId, "som") + this.getUnitErrors(unitId, "tablet")) || "No errors";
            let sef = true;
            if (filters.systemErrors) {
                if (systemErrors === "No errors")
                    sef = false;
                else
                    sef = createUnitErrorRegexFilter(filters.systemErrors).test(systemErrors);
                if (filters.systemErrors[0] === "!")
                    sef = !sef;
            }

            const stf = !this.state.selectedDateRange["somTime"] ? true :
                this.filterByDate(unit?.som?.timestamp, this.state.selectedDateRange["somTime"])

            const ttf = !this.state.selectedDateRange["tabletTime"] ? true :
                this.filterByDate(unit?.tablet?.timestamp, this.state.selectedDateRange["tabletTime"])

            const {som: somV, tablet: tabletV} = UnitsModule.getDevicesVersions(unitId);
            const deviceVersionPattern = createFilterPattern(filters.deviceVersion);
            let opt: boolean = true;
            if (filters.deviceVersion) {
                opt = !!somV && deviceVersionPattern?.test(somV) || !!tabletV && deviceVersionPattern?.test(tabletV);
                if (filters.deviceVersion[0] === "!")
                    opt = !opt;
            }

            const somBuild = this.getBuildNumber(unit?.som?.version);
            const tabletBuild = this.getBuildNumber(unit?.tablet?.version);
            const deviceBuildNumberPattern = createFilterPattern(filters.buildNumber);
            let bn: boolean = true;
            if (filters.buildNumber) {
                bn = !!somBuild && deviceBuildNumberPattern?.test(somBuild) || !!tabletBuild && deviceBuildNumberPattern?.test(tabletBuild);
                if (filters.buildNumber[0] === "!")
                    bn = !bn;
            }

            let af: boolean = true;
            if (filters.alive) {
                af = createFilterPattern(filters.alive)?.test(`${somAlive}`) || createFilterPattern(filters.alive)?.test(`${tabletAlive}`)
                if (filters.alive[0] === "!")
                    af = !af;
            }

            const insta = this.handleInstallationFilter(unitId);

            const rcFilter = this.handleIsRCFilter(unitId);

            const comment = UnitsModule.getUnitUserGroup(unitId);
            let envf: boolean = true;
            if (filters.userGroup) {
                envf = createFilterPattern(filters.userGroup).test(`${comment}`)
                if (filters.userGroup[0] === "!")
                    envf = !envf
            }

            let handshakeFilter: boolean = true;
            if (filters.handshake) {
                handshakeFilter = createFilterPattern(filters.handshake).test(`${unit?.som?.stricklandState}`) || createFilterPattern(filters.handshake).test(`${unit?.tablet?.stricklandState}`)
                if (filters.handshake[0] === "!")
                    handshakeFilter = !handshakeFilter
            }

            const {alias, config} = getAliasAndConfig(unitId);
            let aliasf = true;
            if (filters.aliasConfig) {
                aliasf = createFilterPattern(filters.aliasConfig).test(alias || "") || createFilterPattern(filters.aliasConfig).test(config || "");
                if (filters.aliasConfig[0] === "!")
                    aliasf = !aliasf;
            }

            const filterSerialAndSha = (datatype: keyof DeviceIdentity) => {
                let somData: string | undefined;
                let tabletData: string | undefined;
                if (pairBasic.activation) {
                    somData = pairBasic.somSerial;
                    tabletData = pairBasic.tabletSerial;
                } else if (pairBasic.pair) {
                    const pair = UnitsModule.getIdentity(unitId);
                    const somIdentity = pair?.identities?.find((ident: DeviceIdentity) => ident.type === "som")
                    somData = somIdentity ? somIdentity[datatype] as string : undefined;
                    const tabletIdentity = pair?.identities?.find((ident: DeviceIdentity) => ident.type === "tablet")
                    tabletData = tabletIdentity ? tabletIdentity[datatype] as string : undefined;
                }


                const filter = filters[datatype as string];
                const regExp = createFilterPattern(filter);
                const res = filter ? (somData && regExp.test(somData)) || (tabletData && regExp.test(tabletData)) : true;
                return filter[0] !== "!" ? res : !res;
            }

            const serialf = !filters.serial ? true : filterSerialAndSha("serial");
            const shaf = !filters.sha256 ? true : filterSerialAndSha("sha256");

            const textFilter = uid && wP && mu && vf && vsf && sef && stf && ttf && handshakeFilter && af && envf && aliasf && serialf && shaf && opt && bn && insta && rcFilter;

            const unitEnv = UnitsModule.getUnitUserGroup(unitId);
            const statusFilter = this.handleStatusFilter(unitId, unit, unitEnv);
            const categoryFilter = this.handleCategoryFilter(unitEnv);
            const unitsTypeFilter = handleUnitsTypeFilter(unitId, this.getConsideredEnvs());

            const unitIdentityFilter = this.handleUnitIdentityFilter(pairBasic);

            return textFilter && statusFilter && categoryFilter && unitsTypeFilter && unitIdentityFilter;
        });
    }

    private applyVersionFilter(unit: UnitView) {
        const {filters} = this.state;
        const somVersion = unit?.som?.version;
        const tabletVersion = unit?.tablet?.version;
        let vf = true;
        const applyVersionFilterImpl = (_f: string) => {
            return _f.startsWith("^") ?
                (somVersion ? somVersion.startsWith(_f) : false) || (tabletVersion ? tabletVersion.startsWith(_f) : false) :
                (somVersion ? somVersion.includes(_f) : false) || (tabletVersion ? tabletVersion.includes(_f) : false);
        }

        if (filters.version) {
            let _filter = filters.version
            if (filters.version.startsWith("!"))
                _filter = _filter.substring(1);

            if (_filter.includes(","))
                vf = _filter.split(",").some(_f => applyVersionFilterImpl(_f))
            else
                vf = applyVersionFilterImpl(_filter);

            if (filters.version.startsWith("!"))
                vf = !vf;
        }
        return vf;
    }

    private renderTopTable = (sortedFilteredUnits: string[], allUnits: string[]) => {
        const areThereFilters: boolean = this.getAreThereFilters();
        return <div className={`ll_h_c match_width`} style={{overflowX: "auto"}}>
            <div className={"ll_v_c"} style={{flex: 1, marginBottom: "5px", marginTop: "10px"}}>
                <div style={{marginBottom: "-15px", color: "#3B3B3B", fontSize: "14px"}} className={"ll_v_c"}>
                    <NewStyledDropDown
                        width={175}
                        id={`select-units`}
                        chosenOption={chooseUnitsOptions.find(item => item.value === this.state.unitsType)}
                        onChange={(sel) => {
                            if (!sel.target.value)
                                return;

                            this.setState({unitsType: sel.target.value as UserGroupTypes}, this.persistState);
                        }}
                        options={chooseUnitsOptions}
                    />
                </div>
                <div>
                    {sortedFilteredUnits.length} ElliQs
                </div>
                <div className={"ll_h_c"}>
                    <div
                        className={`btn-nice btn-nice-${areThereFilters} no-wrap`}
                        onClick={() => this.setState(deepClone(this.initFiltersHelper()), this.persistState)}
                    >Clear Filters
                    </div>
                    <PermissionsComponent url={Url_SupportTeam}>
                        <div
                            className={`btn-nice btn-nice-${this.state.batch}`}
                            onClick={() => this.setState(prev => ({
                                batch: !prev.batch,
                                batchUnits: [],
                                checkAll: false
                            }))}>
                            Batch
                        </div>
                    </PermissionsComponent>
                </div>
                {this.state.batch && <PermissionsComponent url={Url_SupportTeam}>
                    <div style={{margin: "5px 0"}}>
                        <Dropdown_BatchActions units={this.state.batchUnits.map(uId => ({unitId: uId, product: Elliq_ProductKey}))}/>
                    </div>
                </PermissionsComponent>}
            </div>
            <Numbers_Table statusFilters={this.state.statusFilters}
                           userGroupFilters={this.state.userGroupFilters}
                           onStatusClick={this.onStatusClick}
                           onUserGroupClick={this.onUserGroupClick}
                           onCellClick={this.clickedOnCell}
                           consideredEnvs={this.getConsideredEnvs()}
            />
        </div>;
    };

    private getAreThereFilters() {
        if (this.state.selectedDateRange && Object.keys(this.state.selectedDateRange).length > 0)
            return true;
        if (Object.keys(this.state.userGroupFilters).some(key => this.state.userGroupFilters[key]))
            return true;
        if (Object.keys(this.state.statusFilters).some(key => this.state.statusFilters[key]))
            return true;
        return Object.keys(this.state.filters).some(key => this.state.filters[key] !== "");
    }

    private getTableBody(sortedFilteredUnits: string[]) {
        if (!sortedFilteredUnits.length)
            return <TableRow key={"no-units"}>
                <TableCell colSpan={100} align="center" style={{color: "red"}}>
                    No units available with the current filter
                </TableCell>
            </TableRow>;

        return sortedFilteredUnits.map(this.renderRow);
    }

    private renderCommentButton = (unitId: string) => {
        const comment = UnitsModule.getGeneralComment(unitId);
        return <img
            alt={"clickable"}
            src={comment ? icon__redPencil : icon__pencil}
            onMouseMove={e => {
                if (comment)
                    showTooltip(e, <div>
                        <pre>{comment}</pre>
                    </div>);
            }}
            onMouseLeave={() => hideTooltip()}
            width={16}
            className="clickable"
            onClick={() => this.showPreview(unitId, comment)}
        />
    }

    private renderCell(cellKey: string, unitId: string, unitView: UnitView, pairBasic?: UnitBasic) {
        const key = `cell_${cellKey}_${unitId}`;
        switch (cellKey) {
            case "unitId":
                return <TableCell key={key} component="th" className={cellStyle}>
                    <div className="ll_h_c">
                        {this.state.batch && <TS_Checkbox
                            value={unitId}
                            checked={!!this.state.batchUnits.find(u => u === unitId)}
                            onCheck={() => {
                                this.setState(prev => {
                                    const index = prev.batchUnits.findIndex(u => u === unitId);
                                    let batchUnits: string[];
                                    if (index === -1)
                                        batchUnits = [...prev.batchUnits, unitId]
                                    else
                                        batchUnits = prev.batchUnits.filter(i => i !== unitId)

                                    return {batchUnits, checkAll: false};
                                });
                            }}
                            label={null}
                        />}
                        <div className={"link-like"}
                             onClick={e => this.onUnitClicked(e, {unitId, product: Elliq_ProductKey})}>{unitId}</div>
                        {this.renderCommentButton(unitId)}
                    </div>
                </TableCell>;
            case "version":
                const somVersion = this.getVersion(unitView?.som?.version);
                const tabletVersion = this.getVersion(unitView?.tablet?.version);
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {`S: ${somVersion}`}
                        </span>
                        <span className={"no-wrap"}>
                            {`T: ${tabletVersion}`}
                        </span>
                    </div>
                </TableCell>
            case "buildNumber":
                const somBuild = this.getBuildNumber(unitView?.som?.version);
                const tabletBuild = this.getBuildNumber(unitView?.tablet?.version);
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {`S: ${somBuild}`}
                        </span>
                        <span className={"no-wrap"}>
                            {`T: ${tabletBuild}`}
                        </span>
                    </div>
                </TableCell>
            case "versionStatus":
                const versionStatus = getVersionStatus(unitView);
                return <TableCell key={key}
                                  className={cellStyle}
                                  style={{color: versionStatus === VersionStatus.sameVersions ? "black" : "red"}}
                >
                    {versionStatus}
                </TableCell>
            case "systemErrors":
                const somErrors = this.getUnitErrors(unitId, "som");
                const tabletErrors = this.getUnitErrors(unitId, "tablet");
                return <TableCell key={key} className={cellStyle}>
                    {`Errors: ${somErrors || "None"}`}
                    {tabletErrors && ![errorDiffVersion].includes(tabletErrors) && <><br/>Errors T: {tabletErrors}</>}
                </TableCell>
            case "isRc":
                return <TableCell key={key} className={cellStyle}>
                    {this.renderIsRC(unitId)}
                </TableCell>
            case "installation":
                return <TableCell key={key} className={cellStyle}>
                    {this.renderPMStatus(unitId)}
                </TableCell>
            case "somTime":
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>{`${this.renderTime(unitView?.som?.timestamp)}`}</span>
                        <span className={"no-wrap"}>{`${unitView?.som?.tz || ""}`}</span>
                    </div>
                </TableCell>
            case "tabletTime":
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>{`${this.renderTime(unitView?.tablet?.timestamp)}`}</span>
                        <span className={"no-wrap"}>{`${unitView?.tablet?.tz || ""}`}</span>
                    </div>
                </TableCell>
            case "strickland":
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {`S: ${(!unitView?.som?.stricklandState) ? "Not connected" : unitView?.som?.stricklandState?.toLowerCase()}`}
                        </span>
                        <span className={"no-wrap"}>
                            {`T: ${(!unitView?.tablet?.stricklandState) ? "Not connected" : unitView?.tablet.stricklandState?.toLowerCase()}`}
                        </span>
                    </div>
                </TableCell>
            case "alive":
                const somAlive = isAlive(unitView?.som?.timestamp);
                const tabletAlive = isAlive(unitView?.tablet?.timestamp);
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {`S: ${somAlive}`}
                        </span>
                        <span className={"no-wrap"}>
                            {`T: ${tabletAlive}`}
                        </span>
                    </div>
                </TableCell>
            case "deviceVersion":
                return <TableCell key={key} className={cellStyle}>
                    {this.renderDeviceVersion(unitId)}
                </TableCell>
            case "userGroup":
                return <TableCell key={key} className={cellStyle}>
                    <div>{UnitsModule.getUnitUserGroup(unitId) || ""}</div>
                </TableCell>
            case "aliasConfig":
                const {alias, config} = getAliasAndConfig(unitId);
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            Alias: {alias || "N/A"}
                        </span>
                        <span className={"no-wrap"}>
                            Config: {config || "N/A"}
                        </span>
                    </div>
                </TableCell>
            case "unitIdentity":
                return <TableCell key={key} className={cellStyle}>
                    {`Activation: ${pairBasic?.activation || false}`}
                    <br/>
                    {`Pairing: ${pairBasic?.pair || false}`}
                </TableCell>
            case "serial":
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {pairBasic?.somSerial}
                        </span>
                        <span className={"no-wrap"}>
                            {pairBasic?.tabletSerial}
                        </span>
                    </div>
                </TableCell>
            case "whoUpdated":
                return <TableCell key={key} className={cellStyle}>
                    {this.getBlame(unitId)}
                </TableCell>
            case "diskMemoryInUse":
                const memoryUsedInPercentSom = this.getMemoryUsedSom(unitId);
                const memoryUsedInPercentTablet = this.getMemoryUsedTablet(unitId);
                return <TableCell key={key} className={cellStyle}>
                    <div className={"ll_v_c"}>
                        <span className={"no-wrap"}>
                            {`S: ${memoryUsedInPercentSom}`}
                        </span>
                        <span className={"no-wrap"}>
                            {`T: ${memoryUsedInPercentTablet}`}
                        </span>
                    </div>
                </TableCell>
        }
    }

    private renderRow = (unitId: string) => {
        const pairBasic = UnitsManagerModule.getUnitBasic(unitId);
        const unitView = UnitsModule.getUnitView(unitId);
        const bgClass = css({background: this.rowColor(unitId, unitView)});
        return (
            <TableRow key={unitId} hover={true} className={bgClass}>
                {this.state.tableHeaderConfig.map((id) => this.renderCell(id, unitId, unitView, pairBasic))}
            </TableRow>
        );
    };

    private getVersion(version: string | undefined | null) {
        const res = new RegExp(/(\d+\.\d+\.\d+-\w+)\.?/).exec(version || "");
        return res ? res[1] : "";
    }

    private getBuildNumber(version: string | undefined | null) {
        const res = new RegExp(/(?:\d+\.\d+\.\d+-\w+)\.(:?\d+)?/).exec(version || "");
        return res ? res[1] : "";
    }

    private getBlame(unitId: string) {
        const activation = ActivationsModule.getActivation(unitId);
        return activation ? activation.blame : UnitsModule.getIdentity(unitId)?._audit?.auditBy || "";
    }

    private renderTime(t?: number | null) {
        if (!t)
            return "";

        return new Date(t).toLocaleString();
    }

    private renderDeviceVersion(unitId: string) {
        const {som: somV, tablet: tabletV} = UnitsModule.getDevicesVersions(unitId);
        const className = (somV && tabletV && somV !== tabletV) ? blinking : "";
        return <>
            <div className={className}>S: {somV || "N/A"}</div>
            <div className={className}>T: {tabletV || "N/A"}</div>
        </>;
    }

    private onUnitClicked = (e: React.MouseEvent, params: { product: string, unitId: string }) => {
        if (e.ctrlKey || e.metaKey)
            return window.open(RoutingModule.getRoute(Route_UnitMonitoring).compose(params), "_blank");

        RoutingModule.goToRoute(Route_UnitMonitoring, params);
    };

    private showPreview = (unitId: string, comment?: string) => {
        new Dialog_Builder(<Component_GeneralComment unitId={unitId} comment={comment}/>)
            .setTitle(unitId)
            .setAllowIndirectClosing(true)
            .setButtons(closeButton("Close"))
            .show();
        return;
    };

    private handleStatusFilter(unitId: string, unitView: UnitView, pairComment?: string) {
        const keysToFilter = Object.keys(this.state.statusFilters).filter(key => this.state.statusFilters[key]);

        if (!keysToFilter.length)
            return true;

        return this.isFiltered(unitId, unitView, keysToFilter);
    }

    private isFiltered = (unitId: string, unitView: UnitView, keysToFilter: string[]) => {
        const status = prepareStatusValues(unitId, unitView);

        for (const filterKey of keysToFilter) {
            if (filterKey === "category" || filterKey === "total")
                return true;
            if (filterKey === status)
                return true;
        }

        return false;
    };

    private handleCategoryFilter(pairComment?: string) {
        const keysToFilter = Object.keys(this.state.userGroupFilters).filter(key => this.state.userGroupFilters[key]);

        if (!keysToFilter.length)
            return true;

        if (!pairComment)
            return false;

        return keysToFilter.some(k => k.toLowerCase() === pairComment.toLowerCase());
    }

    private getUnitErrors(unitId: string, deviceType: DeviceType) {
        const view = UnitsModule.getUnitView(unitId);
        if (ActivationsModule.getActivation(unitId)?.activated && !isDeviceRuntimeStatusExist(view[deviceType]))
            return errorNoRuntimeStatus;

        const somInfo = view?.[deviceType];
        if (somInfo?.errorCodes)
            return somInfo.errorCodes;

        if (isDiffVersionMoreThan30Min(unitId, view))
            return errorDiffVersion;

        return "";
    }

    private getMemoryUsedSom(unitId: string) {
        const view = UnitsModule.getUnitView(unitId);
        const deviceInfo = view?.som;
        const arr = deviceInfo?.flashPartitionsInfo?.split(" ");
        return arr ? arr[arr.length - 2] : "";
    }

    private getMemoryUsedTablet(unitId: string) {
        const view = UnitsModule.getUnitView(unitId);
        const deviceInfo = view?.tablet;
        const arr = deviceInfo?.flashPartitionsInfo?.split(" ");
        return arr ? arr[arr.length - 2] : "";
    }

    private filterByDate(timestamp: number | undefined | null, element: string | {
        from: number,
        to: number
    }) {
        if (element === "All date")
            return true;

        if (!timestamp)
            return false;

        if (typeof element === "string") {
            const selectedDateRangeElement = element.toLowerCase();
            if (selectedDateRangeElement.includes("last")) {
                const rangeValueWithoutExclamation = selectedDateRangeElement.replace("!", "").trim();
                const regExpExecArray = /^last(?: *)?(:?\d*)? (:?hour|day|minute|second)s?$/i.exec(rangeValueWithoutExclamation);
                if (!regExpExecArray || regExpExecArray.length !== 3)
                    return true;

                const number = +regExpExecArray[1] || 1;
                if (isNaN(number))
                    return true;

                const unit = regExpExecArray[2];
                const unitVal = this.getUnitVal(unit);
                if (!unitVal)
                    return true;

                const filterValue = currentTimeMillies() - number * unitVal <= timestamp;
                return selectedDateRangeElement.startsWith("!") ? !filterValue : filterValue;
            } else
                return this.renderTime(timestamp).includes(selectedDateRangeElement)
        } else
            return timestamp >= (element as {
                from: number,
                to: number
            }).from && timestamp < (element as { from: number, to: number }).to;
    }

    private getUnitVal(unit: string) {
        switch (unit) {
            case "second":
                return Second;
            case "minute":
                return Minute;
            case "day":
                return Day;
            case "hour":
                return Hour;
            default:
                return;
        }
    }

    private renderPMStatus(unitId: string) {
        const pmStatuses = UnitsModule.getPmStatus(unitId);
        return <div className={"ll_v_c"}>{pmStatuses?.sort((p1, p2) => p1.deviceType.localeCompare(p2.deviceType)).map(pmStatus => {
            return <span className={"no-wrap"} key={pmStatus._id}>{pmStatus.deviceType[0].toUpperCase()}: {this.getArtifactData(pmStatus)}</span>;
        })}</div>
    }

    private renderIsRC(unitId: string) {
        const rcInstallTime = UnitsModule.getRcInstallTime(unitId);
        const pmStatuses = UnitsModule.getPmStatus(unitId);
        return <div className={"ll_v_c"}>{pmStatuses?.sort((p1, p2) => p1.deviceType.localeCompare(p2.deviceType)).map(pmStatus => {
            return <span className={"no-wrap"} key={pmStatus._id}>{pmStatus.deviceType[0].toUpperCase()}: {!!pmStatus.isRC ? "rc" : "manual"} {rcInstallTime?.[pmStatus.deviceType] || ""}</span>;
        })}</div>
    }

    private getArtifactData(pmStatus: PmStatus): React.ReactNode {
        const artifactData = pmStatus.status.artifact_data;
        if (!artifactData)
            return "N/A";
        const {installed, downloaded, errors} = this.getArtifactStatusValues(artifactData);
        const downloadedSuccess = downloaded / artifactData.length;
        const success = installed / artifactData.length;

        return <>
			<span
                style={{color: errors > 0 ? "red" : downloadedSuccess === 1 ? "green" : "orange"}}>D {downloaded}/{artifactData.length}</span>
            {" - "}
            <span
                style={{color: errors > 0 ? "red" : success === 1 ? "green" : "orange"}}>I {installed}/{artifactData.length}</span>
        </>;
    }

    private getArtifactStatusValues(artifactData: AndroidArtifactData[]) {
        let installed = 0;
        let downloaded = 0;
        let errors = 0;
        for (const artifactDatum of artifactData) {
            switch (artifactDatum.installationState) {
                case InstallationState.InstalledUpToDate:
                    installed++;
                    downloaded++;
                    break;
                case InstallationState.Installing:
                case InstallationState.InstalledDifferentVersion:
                case InstallationState.Downloaded:
                    downloaded++;
                    break;
                case InstallationState.ERROR:
                    errors++;
                    break;
            }
        }

        return {installed, downloaded, errors};
    }

    private handleDiskMemoryInUseFilter(pairBasic: UnitBasic) {
        const {filesystemMemoryUsed} = this.state.filters;
        const filesystemMemoryUsedSom = this.getMemoryUsedSom(pairBasic.unitId) || "";
        const filesystemMemoryUsedTablet = this.getMemoryUsedTablet(pairBasic.unitId) || "";

        let mu = true;
        if (filesystemMemoryUsed) {
            if (filesystemMemoryUsed === "more than 85% in use") {
                const somMemoryNumber = parseInt(filesystemMemoryUsedSom);
                const tabletMemoryNumber = parseInt(filesystemMemoryUsedTablet);
                return somMemoryNumber >= 85 || tabletMemoryNumber >= 85;
            } else {
                mu = createFilterPattern(filesystemMemoryUsed).test(filesystemMemoryUsedSom) || createFilterPattern(filesystemMemoryUsed).test(
                    filesystemMemoryUsedTablet);
                if (filesystemMemoryUsed[0] === "!")
                    mu = !mu;
            }
        }

        return mu;
    }

    private handleIsRCFilter(unitId: string) {
        const isRcFilter = this.state.filters.isRc;
        if (!isRcFilter)
            return true;

        const pmStatuses = UnitsModule.getPmStatus(unitId);
        if (!pmStatuses || pmStatuses.length === 0)
            return false;

        const isUnit3Point0 = UnitsManagerModule.is3Point0Unit(unitId);

        const deviceToIsRc = arrayToMap(pmStatuses.map(pmStatus => {
            let rc;
            let startTime;
            let endTime;

            const isRc = pmStatus.isRC;
            if (!isRc)
                rc = null;
            else
                rc = isRc;

            const startT = pmStatus.startTime;
            if (!startT)
                startTime = null;
            else
                startTime = startT;

            const endT = pmStatus.endTime;
            if (!endT)
                endTime = null;
            else
                endTime = endT;

            return {rc, startTime, endTime, deviceType: pmStatus.deviceType};
        }), pmStatus => pmStatus.deviceType);

        const tabletRc = deviceToIsRc["tablet"]?.rc;
        const somRc = deviceToIsRc["som"]?.rc;

        const tabletStartTime = deviceToIsRc["tablet"]?.startTime;
        const somStartTime = deviceToIsRc["som"]?.startTime;

        const tabletEndTime = deviceToIsRc["tablet"]?.endTime;
        const somEndTime = deviceToIsRc["som"]?.endTime;

        const moreThan10MinInstallation = (startTime: number, endTime: number) => {
            if (endTime - startTime >= 600000)
                return true;

            return false;
        }

        if (isRcFilter === "rc 10+ min" && tabletRc && tabletStartTime && tabletEndTime) {
            if (isUnit3Point0) {
                const moreThan10ForAnyDevice = moreThan10MinInstallation(tabletStartTime, tabletEndTime);
                if (moreThan10ForAnyDevice)
                    return true;
            }

            if (somRc && somStartTime && somEndTime) {
                const moreThan10ForAnyDevice = moreThan10MinInstallation(somStartTime, somEndTime) || moreThan10MinInstallation(tabletStartTime, tabletEndTime);
                if (moreThan10ForAnyDevice)
                    return true;
            }
        }

        if (isRcFilter === "rc" && tabletRc) {
            if (isUnit3Point0)
                return true;

            if (somRc)
                return true;
        }

        if (!isUnit3Point0 && isRcFilter === "rc & manual" && ((somRc && !tabletRc) || (!somRc && tabletRc))) {
            return true;
        }

        if (isRcFilter === "no rc" && !tabletRc) {
            if (isUnit3Point0)
                return true;

            if (!somRc)
                return true;
        }

        if (!isUnit3Point0 && isRcFilter === "s - rc not finished" && somRc && somStartTime && !somEndTime) {
            return true;
        }

        if (isRcFilter === "t - rc not finished" && tabletRc && tabletStartTime && !tabletEndTime) {
            return true;
        }

        return false;
    }

    private handleInstallationFilter(unitId: string) {
        const installationFilter = this.state.filters.installation;
        if (!installationFilter)
            return true;

        const pmStatuses = UnitsModule.getPmStatus(unitId);
        if (!pmStatuses || pmStatuses.length === 0)
            return false;

        const deviceToPMStatus = arrayToMap(pmStatuses.map(pmStatus => {
            let artifact;

            const artifactData = pmStatus.status.artifact_data;
            if (!artifactData)
                artifact = null;
            else
                artifact = artifactData;

            return {artifact, deviceType: pmStatus.deviceType};
        }), pmStatus => pmStatus.deviceType);

        const tabletArtifact = deviceToPMStatus["tablet"]?.artifact;
        const somArtifact = deviceToPMStatus["som"]?.artifact;
        const tabletStatus = this.getArtifactStatusValues(tabletArtifact || []);
        const somStatus = this.getArtifactStatusValues(somArtifact || []);

        const tabletIsPartial = tabletStatus.downloaded < (tabletArtifact?.length || 0) || tabletStatus.installed < (tabletArtifact?.length || 0);
        const somIsPartial = somStatus.downloaded < (somArtifact?.length || 0) || somStatus.installed < (somArtifact?.length || 0);

        if (installationFilter === "tablet partial" && tabletArtifact) {
            return tabletIsPartial;
        }

        if (installationFilter === "som partial" && somArtifact) {
            return somIsPartial;
        }

        if (installationFilter === "partial") {
            return (tabletArtifact && tabletIsPartial) || (somArtifact && somIsPartial)
        }

        if (installationFilter === "complete" && somArtifact && tabletArtifact) {
            return tabletStatus.downloaded === tabletArtifact.length && tabletStatus.installed === tabletArtifact.length && somStatus.downloaded === somArtifact.length && somStatus.installed === somArtifact.length
        }

        return false;
    }

    private validateStateAndTime(stateAndTime?: any): PageUnitsState | undefined {
        if (!stateAndTime)
            return;

        if (typeof stateAndTime !== 'object')
            return;

        if (typeof stateAndTime.time !== 'number')
            return;

        if (stateAndTime.time < currentTimeMillies() - Hour)
            return;

        if (!this.validateState(stateAndTime.state))
            return;

        return stateAndTime.state;
    }

    private validateState(state?: any) {
        if (!state)
            return false;

        if (!state.page)
            return false;

        if (state.checkAll === undefined)
            return false;

        if (!state.filters)
            return false;

        if (state.sortBy === undefined)
            return false;

        if (!state.sortOrder)
            return false;

        return state.loading !== undefined;
    }

    private handleUnitIdentityFilter(pairBasic: UnitBasic) {
        const unitIdentityFilter = this.state.filters.unitIdentity;

        if (!unitIdentityFilter || !UnitIdentitiesType.includes(unitIdentityFilter))
            return true;

        if (unitIdentityFilter === "activation" && !pairBasic.activation)
            return false;

        if (unitIdentityFilter === "pairing" && !pairBasic.pair)
            return false;

        return !(unitIdentityFilter === "both" && (!pairBasic.activation || !pairBasic.pair));

    }
}

export const Page_UnitsWrapper = () => {
    const {userGroups} = useContext(UserGroupsContext);
    return <Page_Units envs={userGroups}/>
}


