import * as React from "react";
import {useEffect, useState} from "react";
import {Message} from "./Conversation";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import {SimpleLoader} from "@components/SimpleLoader";

function MessageRenderer({message}: { message: Message }) {
    const [showExtraParams, setShowExtraParams] = useState(false); // State to manage visibility

    const shouldShowExtraParamRelated = message.extraParams && showExtraParams;
    return (
        <div
            key={message.id}
            className={`message ${(message.sender).toLowerCase()}-message`}
            onClick={() => {
                if (showExtraParams)
                    return;
                setShowExtraParams(true)
            }}
        >
            {shouldShowExtraParamRelated && <div className={"close"} onClick={() => setShowExtraParams(false)}>X</div>}
            {message.text}
            {message.translatedText && <div className="translated-text">{message.translatedText}</div>}
            {shouldShowExtraParamRelated && <div className="extra-params">
                {Object.keys(message.extraParams).map(key => <div key={`${message.id}_${key}`}>{key}: {message.extraParams[key]}</div>)}
            </div>}
        </div>
    );
}

type ChatProps = {
    messages: Message[],
    onToggle: (toggled: boolean) => void,
    onMessageSent: (message: string) => void,
    isToggled: boolean | undefined
};

export function RenderChat({messages, onMessageSent, onToggle, isToggled}: ChatProps) {
    const [typedMessage, setTypedMessage] = useState("");
    const [showInput, setShowInput] = useState(isToggled)
    const [loading, setLoading] = useState(false)

    let t: any;
    const enterPressed = () => {
        t && clearTimeout(t);
        setTypedMessage("");
        onMessageSent(typedMessage);
        setLoading(true);
        t = setTimeout(() => setLoading(false), 3000);
    };

    useEffect(() => {
        setShowInput(isToggled);
    }, [isToggled]);

    useEffect(() => {
        setLoading(false);
        t && clearTimeout(t);
    }, [messages.length]);

    return (
        <div className="conversation-container">
            <div className={"ll_h_c space-between"}>
                <h2>Conversation</h2>
                {isToggled !== undefined && <Switch
                    onClick={() => {
                        setShowInput(s => {
                            onToggle(!s)
                            return !s;
                        })
                    }}
                    checked={showInput}
                    inputProps={{'aria-label': 'controlled'}}
                />}
            </div>
            <div className="message-container">
                {messages.map((message) => <MessageRenderer key={message.id} message={message}/>)}
            </div>
            {showInput && <div className={"ll_h_c space-between"}>
                <TextField
                    id="filled-basic"
                    variant="standard"
                    onChange={(e) => setTypedMessage(e.target.value)}
                    placeholder="Type your message..."
                    onKeyDown={e => e.key === "Enter" && enterPressed()}
                    value={typedMessage}
                />
                <Button
                    variant="contained"
                    onClick={() => enterPressed()}
                >Send</Button>
            </div>}
            {loading && <SimpleLoader backgroundColor={"transparent"} absolute={true}/>}
        </div>)
}
