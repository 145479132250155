import * as  React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {UnitContext, UserGroupAction, UserGroupsContext} from "../../App";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Collapse, IconButton} from "@mui/material";
import {SimpleLoader} from "@components/SimpleLoader";
import {icon_cancel, icon_check, NewStyledDropDown} from "@components/NewStyledDropDown";
import {auditBy} from "@intuitionrobotics/ts-common";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {UnitsModule} from "@modules/UnitsModule";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";
import {DataEnvItem, UserGroupTypes} from "@app/ir-q-app-common/types/units";
import {initUnitsType} from "../units/Page_Units";

export const chooseUnitsOptions = [{key: UserGroupTypes.USERS, value: UserGroupTypes.USERS}, {key: UserGroupTypes.DEMO, value: UserGroupTypes.DEMO}, {key: UserGroupTypes.IR_INTERNAL, value: UserGroupTypes.IR_INTERNAL}];

function MyTableRow({subuserGroups, group, onEnvChange, onEnvDelete, howManyUnits}: { subuserGroups?: Map<string, number>, group: DataEnvItem, howManyUnits?: number, onEnvChange: (type: UserGroupTypes) => void, onEnvDelete: () => void }) {
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment key={group.label}>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell style={{maxWidth: 10, padding: 0}} size={"small"}>
                    {subuserGroups && subuserGroups.size > 0 && <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>}
                </TableCell>
                <TableCell component="th" scope="row">
                    {group.label}
                </TableCell>
                <TableCell component="th" scope="row">
                    {subuserGroups ? subuserGroups.size : 0}
                </TableCell>
                <TableCell component="th" scope="row">
                    {howManyUnits || 0}
                </TableCell>
                <TableCell align="right">{group.audit.auditBy.replace("@intuitionrobotics.com", "")}</TableCell>
                <TableCell align="right">{new Date(group.audit.auditAt.timestamp).toLocaleDateString()}</TableCell>
                <TableCell align="right">{
                    <div style={{height: "40px", display: "flex", justifyContent: "end"}}>
                        <NewStyledDropDown
                            id={`select-units`}
                            width={150}
                            chosenOption={chooseUnitsOptions.find(item => item.value === group.type)}
                            onChange={(sel) => {
                                if (!sel.target.value)
                                    return;

                                onEnvChange(sel.target.value as UserGroupTypes);
                            }}
                            options={chooseUnitsOptions}
                        />
                    </div>
                }</TableCell>
                <TableCell align="right">{howManyUnits ? null :
                    <div className="ll_v_c">
                        <div
                            style={{marginLeft: "5px", height: "24px", width: "24px"}}
                            className={'ll_v_c'}
                            onClick={(e) => {
                                e.stopPropagation();
                                return onEnvDelete();
                            }}
                        >
                            <img alt={'delete'} src={require("@res/images/icon__trash_modern.svg")}/>
                        </div>
                    </div>
                }</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {subuserGroups && Array.from(subuserGroups).map((subGroup, idx) => {
                            return <Box key={subGroup[0] + idx} sx={{margin: 1}}>
                                <strong>Name:</strong> {subGroup[0]} ({subGroup[1]})
                            </Box>
                        })}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Page_Admin() {
    const {userGroups, dispatch} = useContext(UserGroupsContext);
    const {activatedUnits, pairedUnits} = useContext(UnitContext);
    const allUnits = new Set(activatedUnits);
    pairedUnits.forEach(pu => allUnits.add(pu));
    const [userGroupsToSub, setUserGroupsToSub] = useState<Map<string, Map<string, number>>>(new Map())
    const [userGroupCount, setUserGroupCount] = useState<Map<string, number>>(new Map())
    useEffect(() => {
        UnitsModule.queryMetadataSync({dataKey: {$in: [CONST_KS_UserGroup, CONST_KS_SubUserGroup]}}).then(async (metas) => {
            const tempMap = new Map<string, Map<string, number>>();
            const unitToUserGroup = new Map<string, string>();
            const unitToSubGroup = new Map<string, string>();
            const _userGroupCount = new Map<string, number>();
            const _subUserGroupCount = new Map<string, number>();
            metas.forEach(meta => {
                if (meta.dataKey === CONST_KS_UserGroup) {
                    const userGroup = meta.data;
                    unitToUserGroup.set(meta.unitId, userGroup);
                    tempMap.set(userGroup, new Map<string, number>());
                    if (allUnits.has(meta.unitId))
                        _userGroupCount.set(userGroup, (_userGroupCount.get(userGroup) || 0) + 1);
                    return;
                }
                if (meta.dataKey === CONST_KS_SubUserGroup) {
                    unitToSubGroup.set(meta.unitId, meta.data);
                    if (allUnits.has(meta.unitId))
                        _subUserGroupCount.set(meta.data, (_subUserGroupCount.get(meta.data) || 0) + 1);
                }
            })
            setUserGroupCount(_userGroupCount);
            unitToUserGroup.forEach((userGroup, unitId) => {
                const subGroup = unitToSubGroup.get(unitId);
                subGroup && tempMap.get(userGroup)?.set(subGroup, (_subUserGroupCount.get(subGroup) || 0));
            })
            setUserGroupsToSub(tempMap);
        }).catch(console.error)
    }, [])
    const [loading, setLoading] = useState(false);
    const createUserGroup = useCallback((ug: string) =>
        dispatch(UserGroupAction.CREATE, {
            _id: ug,
            audit: auditBy(StorageKey_UserEmail.get()),
            type: initUnitsType(),
            label: ug
        }), [dispatch]);
    return <div>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>
                            <div className={"ll_h_c"}>Label <AddUserGroup addItem={createUserGroup}/></div>
                        </TableCell>
                        <TableCell align="left">N Sub Groups</TableCell>
                        <TableCell align="left">N Units</TableCell>
                        <TableCell align="right">Blame</TableCell>
                        <TableCell align="right">When</TableCell>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userGroups.map((group) => {
                        return <MyTableRow key={group.label} howManyUnits={userGroupCount.get(group.label)} subuserGroups={userGroupsToSub.get(group.label)} group={group} onEnvChange={(type) => {
                            setLoading(true)
                            dispatch(UserGroupAction.UPDATE, {...group, type})
                                .catch(console.error)
                                .finally(() => setLoading(false))
                        }} onEnvDelete={() => {
                            setLoading(true)
                            dispatch(UserGroupAction.DELETE, group)
                                .catch(console.error)
                                .finally(() => setLoading(false))
                        }}/>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        {loading && <SimpleLoader overlay/>}
    </div>


}

const AddUserGroup = ({addItem}: { addItem: (value: string) => Promise<void> }) => {
    const [valueToAdd, setValueToAdd] = useState("")
    const [addDisabled, setAddDisabled] = useState(false)
    const style: React.CSSProperties = {
        height: 40,
        width: 280,
        borderColor: '#1876d2',
        border: "0.9px solid #C0C0C0",
        borderRadius: 10,
        position: "relative",
        marginLeft: 20,
        marginRight: 10
    };
    const size = 24;
    return <div className={"ll_h_c match_width justify-start"}>
        <div style={style}>
            <input
                type={"text"}
                key={"addUserGroup"}
                id={"addUserGroup"}
                name={"addUserGroup"}
                value={valueToAdd}
                onChange={(e) => {
                    setValueToAdd(e.target.value)
                }}
                disabled={addDisabled}
                className={"input-drop-down-with-add"}
            />
            {valueToAdd && <img
                className="removeClick"
                alt={"cancel"}
                onClick={() => {
                    setValueToAdd("")
                }}
                style={{cursor: "pointer"}}
                width={24}
                height={24}
                src={icon_cancel}
            />}
        </div>
        <div className={"btn-activation blue"}><img
            alt={"check"}
            onClick={async () => {
                if (!valueToAdd || !addItem)
                    return;
                setAddDisabled(true)
                await addItem(valueToAdd);
                setValueToAdd("")
                setAddDisabled(false)
            }}
            style={{cursor: "pointer"}}
            width={size}
            height={size}
            src={icon_check}
        />
        </div>
    </div>
}
