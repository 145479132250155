import * as React from "react";
import * as emotion from "emotion";

export const spin = emotion.keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const loader = emotion.css`
	border: 8px solid transparent;
	border-radius: 50%;
	border-top: 8px solid #3498db;
	border-bottom: 8px solid #3498db;
	border-right: 8px solid #3498db;
	width: 30px;
	height: 30px;
	animation: ${spin} 2s linear infinite;
`;

type Props = {
	message?: string
	overlay?: boolean
	absolute?: boolean
	noText?: boolean
	backgroundColor?: string
}

export class SimpleLoader
	extends React.Component<Props> {

	render() {
		const spinner = this.renderSpinner();
		const background = this.props.backgroundColor || '#dedede50';
		if (this.props.overlay)
			return <div
				id={'loader'}
				className="match_width match_height full_screen"
				style={{background: background, zIndex: 999999}}>
				{spinner}
			</div>;

		if (this.props.absolute)
			return <div
				id={'absolute-loader'}
				className="match_width match_height"
				style={{background: background, zIndex: 999999, position: 'absolute'}}>
				<div
					id={'absolute-loader-inner'}
					style={{height: '100%'}}
				>
					{spinner}
				</div>
			</div>;

		return spinner;
	}

	private renderSpinner = () => (
		<div className="ll_h_c fill match_height">
			<div className="ll_v_c match_width">
				<div className={`${loader}`}/>
				{!this.props.noText && <div style={{marginTop: '20px', color: '#f9f9f9'}}>{this.props.message ? this.props.message : "Loading..."}</div>}
			</div>
		</div>
	);
}
