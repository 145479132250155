import * as React from "react";
import {PC_Reboot, PCT_ResetHandshake, PC_UL, PushCommand, PC_UploadLogsViaStrickland, PCT_ShutdownTablet} from "@app/ir-q-app-common/types/push-commands";
import {Button} from "@mui/material";
import {
	DeviceType,
	Unit
} from "@app/ir-q-app-common/types/units";
import {PushMessagesModule} from "@modules/PushMessagesModule";
import {ConfirmationModule} from "@modules/confirmation-modal-module";
import {Minute} from "@intuitionrobotics/ts-common";
import {AppPackage_PM, AppPackage_TabletElliQ} from "@app/ir-q-app-common/types/push-messages";

type CommandProps = { command: PushCommand, unit: Unit, device: DeviceType, autoWidth?: boolean }

export const Renderer_Command = (props: CommandProps) => {

	return <Button
		key={props.command.key}
		size="small"
		fullWidth
		variant="outlined"
		className={props.autoWidth ? "unit_monitoring_btn_auto_width" : "unit_monitoring_btn"}
		style={{margin: "2px 2px 2px 0", textTransform: "none"}}
		onClick={() => {
			const doAction = () => {
				if (props.command.key === PC_UL.key) {
					const prevPackage = props.command.targetPackage;
					const prevKey = props.command.key;
					return PushMessagesModule.pushCommandByVersionToDevice([props.unit], [props.device], PC_UploadLogsViaStrickland.key, "3.0.0", 5 * Minute, prevPackage, prevKey)
				}

				if (props.command.key === PCT_ResetHandshake.key || props.command.key === PC_Reboot.key || props.command.key === PCT_ShutdownTablet.key) {
					const prevPackage = props.command.key !== PCT_ResetHandshake.key ? AppPackage_PM : AppPackage_TabletElliQ;
					return PushMessagesModule.pushCommandByVersionToDevice([props.unit], [props.device], props.command.key, "3.8.0", 5 * Minute, prevPackage)
				}

				PushMessagesModule.pushMessageToUnits([props.unit], props.command.key, props.command.data, [props.device], props.command.targetPackage);
			};
			if (!props.command.confirmationDialogue)
				return doAction();

			const params = {
				title: 'Are you sure you want to perform',
				bodyText: props.command.label + "\n" + `on ${props.device} of unit` + "\n" + props.unit.unitId,
				onSubmit: doAction,
				submitButtonText: "Go!"
			};
			return ConfirmationModule.confirmationDialog(params)
		}}>
		{props.command.label}
	</Button>;
};
