import {_clearInterval, _setInterval, _setTimeout, currentTimeMillies, Module, ObjectTS, Second} from "@intuitionrobotics/ts-common";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {BaseHttpRequest, ErrorResponse, HttpMethod} from "@intuitionrobotics/thunderstorm";
import {
    ApiDeleteActivations,
    ApiListDeletedActivationsHistory,
    ApiRegisterActivation,
    ApiRenameActivation,
    ApiSupportListActivations,
    ApiUpdateActivations,
    ApiUpdateWithMetadataActivations,
    Body_Activation,
    Body_DeleteActivation,
    Body_RenameActivation,
    Body_UpdateActivation,
    Body_UpdateActivationWithMetadata,
    DBActivationsInterface
} from "@app/ir-q-app-common/types/activation";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated} from "@intuitionrobotics/user-account/frontend";
import {UnitsModule} from "@modules/UnitsModule";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {SerialNotAvailableError, UnitIdWasInUse, UnitIdWasInUseError} from "@app/ir-q-app-common/shared/errors";
import {getAliasAndConfig} from "../app/pages/units/utils";
import {ActivationDefaultParametersWithAuthType} from "../app/pages/pairing/Page_Activation";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";

export const RequestKey_RegisterActivation = "RegisterActivation";
export const RequestKey_RenameActivation = "RenameActivation";
export const RequestKey_ListActivations = "ListActivations";
export const RequestKey_ListDeletedActivationsHistory = "ListDeletedActivationsHistory";
export const RequestKey_UpdateActivations = "UpdateActivations";
export const RequestKey_DeleteActivations = "DeleteActivations";
export const URL_ActivationsDelete = `/v1/unit/activations/delete`;

export interface OnActivationRegisterUnitIdWasInUse {
    __onActivationRegisterUnitIdWasInUse(params: Body_Activation, hasFMContacts: boolean): void;
}

const dispatch_onActivationRegisterUnitIdWasInUse = new ThunderDispatcher<OnActivationRegisterUnitIdWasInUse, "__onActivationRegisterUnitIdWasInUse">("__onActivationRegisterUnitIdWasInUse");

export interface OnModal {
    __onModal(modalType: string, deviceType?: string, data?: any, isActivation?: boolean): void;
}

const dispatchModal = new ThunderDispatcher<OnModal, "__onModal">("__onModal");

export class ActivationsModule_Class
    extends Module implements OnLoginStatusUpdated {
    onLoginStatusUpdated = () => {
        if (AccountModule.getLoggedStatus() === LoggedStatus.LOGGED_IN)
            this.list();
    };
    // Assuming we'll never change the uniqueness of an activation (agent_id)
    private activationMap: { [agentId: string]: DBActivationsInterface | undefined } = {};
    private listTries = 0;
    private listInterval?: number;

    startAutomaticRefresh(unitId?: string) {
        this.listInterval = _setInterval(() => this.list(unitId ? [unitId] : undefined), 60 * Second);
    }

    stopAutomaticRefresh() {
        if (this.listInterval)
            _clearInterval(this.listInterval);
    }

    getActivation = (agentId: string) => this.activationMap[agentId];

    getActivatedUnitIds = () => Object.keys(this.activationMap);

    errorFunc = (request: BaseHttpRequest<any>, resError?: ErrorResponse<UnitIdWasInUse | ObjectTS>, params?: any, isActivation = false): any => {
        const error = resError?.error;
        const errorType = error?.type;
        const hasFMContacts = error?.body?.hasFMContacts || false;
        switch (errorType) {
            case UnitIdWasInUseError:
                if (params)
                    dispatch_onActivationRegisterUnitIdWasInUse.dispatchUI(params, hasFMContacts);
                break;
            case SerialNotAvailableError:
                const body = error?.body as ObjectTS;
                dispatchModal.dispatchUI(SerialNotAvailableError, body.device, params, isActivation);
                break;
            default:
                ToastModule.toastError(`Error registering unit ${params?.unitId}`);
        }
    };

    activate(params: Body_Activation, skipHistoryCheck?: boolean, skipInventoryCheck?: boolean) {
        const {unitId, somSerial, tabletSerial} = params;
        ToastModule.toastInfo(`Activating unit: ${unitId} with som serial ${somSerial} and tablet serial ${tabletSerial}`)
        XhrHttpModule
            .createRequest<ApiRegisterActivation>(HttpMethod.POST, RequestKey_RegisterActivation)
            .setJsonBody({...params, skipHistoryCheck: !!skipHistoryCheck, skipInventoryCheck: !!skipInventoryCheck})
            .setRelativeUrl(`/v1/unit/activations/registration`)
            .setLabel(`Registering unit ${unitId}`)
            .setOnError((request, resError?: ErrorResponse<UnitIdWasInUse>) => this.errorFunc(request, resError, {...params, skipHistoryCheck: !!skipHistoryCheck}, true))
            .execute(async (response) => {
                // hack bc return type is also void
                if (!response)
                    return;

                ToastModule.toastInfo(`Success registering unit: ${unitId} with pincode: ${response.pincode} and contactId ${response.contact._id}`)
                this.list([unitId]);
            });
    }

    rename(params: Body_RenameActivation, skipHistoryCheck?: boolean) {
        const {unitId, somSerial, tabletSerial} = params;
        ToastModule.toastInfo(`Activating unit: ${unitId} with som serial ${somSerial} and tablet serial ${tabletSerial}`)
        XhrHttpModule
            .createRequest<ApiRenameActivation>(HttpMethod.POST, RequestKey_RenameActivation)
            .setJsonBody({...params, skipHistoryCheck: !!skipHistoryCheck})
            .setRelativeUrl(`/v1/unit/activations/rename`)
            .setLabel(`Renaming to ${unitId}`)
            .setOnError((request, resError?: ErrorResponse<UnitIdWasInUse>) => this.errorFunc(request, resError, params, false))
            .execute(async (response) => {
                // hack bc return type is also void
                if (!response)
                    return;

                ToastModule.toastInfo(`Success registering unit: ${unitId} with pincode: ${response.pincode} and contactId ${response.contact._id}`)
                this.list([unitId]);
                this.activationMap[params.unitId] = {...this.activationMap[params.originalUnitId] as DBActivationsInterface, registration_date: currentTimeMillies()}
                delete this.activationMap[params.originalUnitId]
            });
    }

    list(unitIds?: string[]) {
        const xhrHttpRequest = XhrHttpModule
            .createRequest<ApiSupportListActivations>(HttpMethod.POST, RequestKey_ListActivations)
            .setRelativeUrl(`/v1/unit/activations/list`);

        if (unitIds && unitIds.length) {
            xhrHttpRequest
                .setJsonBody({agentIds: unitIds})
        }

        xhrHttpRequest.setLabel(`Listing activations`)
            .setOnError(() => {
                ToastModule.toastError(`Error listing activations`)
                if (this.listTries < 10)
                    this.listTries++;

                _setTimeout(() => this.list(), (10 * 1.2 ** this.listTries) * Second);
            })
            .execute(async (response: DBActivationsInterface[]) => {
                this.listTries = 0;

                for (const r of response) {
                    this.activationMap[r.agent_id] = r;
                }
            });
    }

    async queryDeletedActivationList() {
        return XhrHttpModule
            .createRequest<ApiListDeletedActivationsHistory>(HttpMethod.GET, RequestKey_ListDeletedActivationsHistory)
            .setRelativeUrl(`/v1/unit/activations/deleted-list`).setLabel(`Listing activations history`)
            .setOnError(() => {
                ToastModule.toastError(`Error listing deleted activations`);
            })
            .executeSync();
    }

    generateSixDigitString = () => {
        const min = 0;
        const max = 999999;
        const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
        return randomNumber.toString().padStart(6, '0');
    }

    generateNewPincodeIfNeeded = (body: Body_UpdateActivationWithMetadata) => {
        const existingActivation = this.activationMap[body.agent_id as string];
        if (existingActivation && existingActivation.auth_type === "BIRTHDAY" && body.auth_type !== "BIRTHDAY")
            body.pincode = this.generateSixDigitString();
    }

    update(body: Body_UpdateActivation, birthday?: number, callback?: () => void) {
        if (body.som_pk === undefined || body.tablet_pk === undefined)
            return ToastModule.toastError("You have to pass som_pk and tablet_pk");

        XhrHttpModule
            .createRequest<ApiUpdateActivations>(HttpMethod.POST, RequestKey_UpdateActivations)
            .setRelativeUrl(`/v1/unit/activations/update`)
            .setJsonBody({...body, birthday})
            .setLabel(`Updating activation for unit ${body.agent_id}`)
            .setOnError(`Error updating activations`)
            .execute(async () => {
                const before = this.activationMap[body.agent_id];
                this.activationMap[body.agent_id] = body;

                // If I de-activate a unit I might need to fetch its identity
                if (before?.activated && body.activated === false && UnitsModule.getIdentity(body.agent_id)) {
                    UnitsModule.fetchRuntimeStatusImpl({unitId: body.agent_id, product: Elliq_ProductKey});
                }

                callback?.();
            });
    }

    updateWithMetadata(body: Body_UpdateActivationWithMetadata, skipInventoryCheck?: boolean) {
        this.generateNewPincodeIfNeeded(body);
        XhrHttpModule
            .createRequest<ApiUpdateWithMetadataActivations>(HttpMethod.POST, RequestKey_UpdateActivations)
            .setRelativeUrl(`/v1/unit/activations/update-with-metadata`)
            .setJsonBody({...body, skipInventoryCheck: !!skipInventoryCheck})
            .setLabel(`Updating activation for unit ${body.agent_id}`)
            .setOnError((request, resError?: ErrorResponse<UnitIdWasInUse>) => this.errorFunc(request, resError, body, false))
            .execute(async () => {
                const agentId = body.agent_id as string;
                this.list([agentId]);
            });
    }

    unregister(body: Body_DeleteActivation, callback?: () => void) {
        XhrHttpModule
            .createRequest<ApiDeleteActivations>(HttpMethod.POST, RequestKey_DeleteActivations)
            .setRelativeUrl(URL_ActivationsDelete)
            .setJsonBody(body)
            .setLabel(`Deleting activation for unit ${body.agent_id}`)
            .setOnError(`Error deleting activations`)
            .execute(() => {
                if (UnitsModule.getIdentity(body.agent_id)) {
                    UnitsModule.fetchRuntimeStatusImpl({unitId: body.agent_id, product: Elliq_ProductKey});
                }

                delete this.activationMap[body.agent_id];
                callback?.();
            });
    }

    getDefaultValues(unitId: string): ActivationDefaultParametersWithAuthType {
        const defaultValues: ActivationDefaultParametersWithAuthType = {
            city: "San Francisco",
            state: "California",
            region: "San Francisco Bay Area",
            country: "US",
            firstName: "Mary",
            lastName: "Smith",
            phoneNumber: "+1111111111",
            authType: "AUTO"
        };

        let userGroup, subUserGroup;
        UnitsModule.getUnitMetadata(unitId)?.forEach(meta => {
            if (meta.dataKey === CONST_KS_UserGroup) {
                userGroup = meta.data;
            }
            if (meta.dataKey === CONST_KS_SubUserGroup) {
                subUserGroup = meta.data;
            }
        });

        if (userGroup)
            defaultValues.userGroup = userGroup;

        if (subUserGroup)
            defaultValues.subUserGroup = subUserGroup;

        const {alias, aliasId} = getAliasAndConfig(unitId);
        if (alias && aliasId) {
            defaultValues.aliasName = alias;
            defaultValues.alias = aliasId;
        }

        return defaultValues;
    }
}

export const ActivationsModule = new ActivationsModule_Class("ActivationsModule");
