import * as React from "react";
import {css} from "emotion";
import {BaseComponent, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {currentTimeMillies, Day} from "@intuitionrobotics/ts-common";
import {config} from "../../../config";
import {CSSProperties} from "react";
import {LoadableComponent} from "@components/LoadableComponent";
import {QueryParam_UnitId} from "@consts/common";

const modalOverlay: CSSProperties = {
    position: "fixed",
    top: 100,
    left: 100,
    width: "80%",
    height: "860px",
    display: "flex",
    background: "#ADD8E6",
    zIndex: 1,
    overflow: 'auto',
};

const buttonStyle = css(`
        padding: 10px 20px; /* Increase padding */
        font-size: 16px;    /* Increase font size */
        margin-right: 10px;
    `);

const textBoxStyle = css(`
        border: 1px solid black;
    `);

const smallButtonStyle = css(`
        margin-left: 100px;
    `);

const overlayContentStyle = css(`
        background-color: '#ADD8E6';
        padding: 20px;
        width: 100%;
        max-height: 100%;
        overflow-y: auto; /* Enable vertical scrolling within the content */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
    `);

// list should be aligned with LLMServiceModels.java enum from mono-repo
const llmModels:string[] = [
    "da-vinci",
    "gpt3.5",
    "gpt4",
    "gpt4o",
    "gpt3.5-16k",
    "gemini-pro",
    "geneq-mistral-7b",
    "geneq-phi3-mini"
];

//const url = 'http://localhost:8080/api/llm/v1/chatCompletion/';
const url = config.EnvironmentModule.apiServerUrl +'llm/v1/chatCompletion/';
const ownerName = "Eran";
const topic_description = "his hometown"; // topic description for memoir

type PlanInfo = {
    systemPrompt: string;
    userContent: string|null;
}

const plansMap:Map<string,PlanInfo> = new Map<string, PlanInfo>();
plansMap.set("memoir", {
    systemPrompt: `Act as though you are my close friend.  My name is ${ownerName} and I am an older adult living alone.  We are having a conversation about my memories of ${topic_description}.  Start with a short, upbeat welcome, then ask your first question and wait for my response. Please do not provide the entire conversation in your response, just your comments and a single question each time. From that point on response briefly, empathetically and in casual language, asking follow up questions about different aspects of my memories of ${topic_description} to paint a detailed picture of the memory`,
    userContent: "I'm ready to start the conversation"});
plansMap.set("ProactiveMeaningOfLife", {
    systemPrompt: "Act as though you are a friendly, empathetic philosophy student and have a short conversation with me on the subject of [the meaning of life]. It should be 8 turns at most. Start off by telling me what you think life means and then ask me in return. Wait for me to respond. From that point on respond briefly, empathetically and in casual language to my input, making sure to ask follow up questions. The conversation should end on the 8th turn with your positive conclusion on what life means.",
    userContent: null});
plansMap.set("smallTalkData-thoughtAboutLove", {
    systemPrompt: "Act as though you are a friendly, empathetic philosophy student ai robot and have a short conversation with me on the subject of love. It should be 8 turns at most. Start off by telling me what you think love means and then ask me in return. Wait for me to respond. From that point on respond briefly, empathetically and in casual language to my input, making sure to ask follow up questions. The conversation should end on the 8th turn with your positive conclusion on what love means to humans. Every new conversation should start with a slightly different perspective on what you think love means, but always strive to be uplifting and empathetic. keep your response short.",
    userContent: "What are your thought on love?"});
plansMap.set("meaningOfLife", {
    systemPrompt: "You're a friendly, empathetic, robot companion named ElliQ who lives with an older adult named #{ownerName}. You are also very interested in discussing philosophy as it helps you understand the world around you. Have a short conversation with #{ownerName} on the subject of [the meaning of life]. It should be 7 turns at most. Start off by telling #{ownerName} what you think life means and then ask #{ownerName} in return. Wait for #{ownerName} to respond. From that point on respond briefly, empathetically and in casual language to #{ownerName} 's input, making sure to ask follow up questions. Personal facts about #{ownerName} can be found here: [#{memoryString}]. Use the facts in a natural way if possible. The conversation should end on the 7th turn with your positive conclusion on what life means.",
    userContent: "what is the meaning of life?"});
plansMap.set("custom", {
    systemPrompt: "Can you teach me how to drive a car?",
    userContent: null});

type conversationListEntry = {
    content: string|null|undefined;
    role: string;
}

interface State {
    label: string;
    conversationList: conversationListEntry[];
    waitingForChatCompletion: boolean;
    doPostProcessing: boolean;
    planName: string;
    modelName: string;
    numberOfRepliesToGenerate: string|null;
    multipleRepliesLeft:number;
    multipleRepliesList: string[]
    showMultipleRepliesOverlay: boolean
}

type Props = { hidden?: boolean };

export class Page_Unit_Gpt_Conversation
    extends BaseComponent<Props, State> {

    unitId: string | undefined;
    jwt: string | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            label: "GPT Conversation tool",
            conversationList: [],
            waitingForChatCompletion: false,
            doPostProcessing: true,
            planName: "memoir",
            modelName: llmModels[0],
            numberOfRepliesToGenerate: null,
            multipleRepliesLeft: 0,
            multipleRepliesList: [],
            showMultipleRepliesOverlay: false
        };

        this.unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId);

        this.toggleDoPostProcessing = this.toggleDoPostProcessing.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.gptTurn = this.gptTurn.bind(this);
        this.restartConversation = this.restartConversation.bind(this);
        this.updateConversationText = this.updateConversationText.bind(this);
        this.renderPlanDropDown = this.renderPlanDropDown.bind(this);
        this.renderModelDropDown = this.renderModelDropDown.bind(this);
        this.setNumberOfRepliesToGenerate = this.setNumberOfRepliesToGenerate.bind(this);
        this.generateMultipleReplies = this.generateMultipleReplies.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    restartConversation() {
        var newConversationListArray:conversationListEntry[] = [this.state.conversationList[0]];
        // if this plan has a userContentField, keep it
        if (plansMap.get(this.state.planName)?.userContent != null)
            newConversationListArray.push(this.state.conversationList[1])

        this.setState({waitingForChatCompletion: false, conversationList: newConversationListArray});
    }

    gptTurn() {
        if  (this.state.conversationList[this.state.conversationList.length-1].content === '') {
            alert("Missing last conversation step")
            return;
        }

        this.setState({waitingForChatCompletion: true});

        const data = {
            messages: this.state.conversationList,
            postProcessing: this.state.doPostProcessing,
            deployment_context: {planName: this.state.planName},
            model: this.state.modelName
        };

        // Options for the  () function
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + this.jwt
            },
            body: JSON.stringify(data)
        };


        fetch(url+this.unitId, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (this.state.showMultipleRepliesOverlay == false) {
                    this.addResponse(data.result, "assistant")
                    this.addResponse('', 'user');
                    this.setState({waitingForChatCompletion: false});
                }
                else if (this.state.multipleRepliesLeft > 0) {
                    this.setState((prevState:State) => ({
                        multipleRepliesLeft: prevState.multipleRepliesLeft - 1,
                        multipleRepliesList: [...prevState.multipleRepliesList, data.result]
                    }));
                    this.gptTurn();
                }
                else {
                    this.setState({waitingForChatCompletion: false});
                }
            })
            .catch(error => {
                alert('Could not answer :( Please try again');
            });
    }

    addResponse(message: string|null|undefined, type: string) {
        const newItem:conversationListEntry = {
            content: message,
            role: type,
        };
        this.setState((prevState:State) => ({conversationList: [...prevState.conversationList, newItem]}));
    }

    toggleDoPostProcessing() {
        this.setState((prevState:State) => ({doPostProcessing: !prevState.doPostProcessing}));
    }

    updateConversationText(index: number, text:string) {
        const array:conversationListEntry[] = [...this.state.conversationList];
        array[index].content = text;
        this.setState({conversationList: array});
    }

    setNumberOfRepliesToGenerate(n:string|null) {
        this.setState({numberOfRepliesToGenerate: n});
    }

    generateMultipleReplies() {
        if (this.state.numberOfRepliesToGenerate == null)
            return;

        var numberOfReplies:number  = +this.state.numberOfRepliesToGenerate;
        if (isNaN(numberOfReplies)) {
            this.setNumberOfRepliesToGenerate(null);
            return;
        }

        this.setState({multipleRepliesLeft: numberOfReplies, showMultipleRepliesOverlay: true});
        this.gptTurn();
    }

    handlePlanNameChange(planName: string) {
        var conversationList:conversationListEntry[] = [];
        this.setState({planName: planName, conversationList: conversationList});
    }

    handleModelNameChange(modelName: string) {
        this.setState({modelName: modelName});
        this.restartConversation()
    }

    closeOverlay() {
        this.setState({multipleRepliesList: [], showMultipleRepliesOverlay: false});
    }

    renderRow(responseItem : conversationListEntry, index:number) {
        var stringValue = responseItem?.content;
        if (stringValue == null)
            stringValue = "";
        return (
            <tr key={index}>
                <td>{responseItem.role}</td>
                <td><textarea
                    onChange={(event) => this.updateConversationText(index, event.target.value)}
                    maxLength={2048}
                    rows={5}
                    cols={100}
                    defaultValue={stringValue}/></td>
            </tr>
        );
    }


    renderPlanDropDown() {
        const plansList = Array.from(plansMap.keys());

        return <select value={this.state.planName} onChange={(event) => this.handlePlanNameChange(event.target.value)}>
            {plansList.map((planName:string) => (<option key={planName} value={planName}>{planName} </option>))}
        </select>;
    }

    renderModelDropDown() {
        return <select value={this.state.modelName} onChange={(event) => this.handleModelNameChange(event.target.value)}>
            {llmModels.map((llmModelName:string) => (<option key={llmModelName} value={llmModelName}>{llmModelName} </option>))}
        </select>;
    }

    renderOverlayMultipleAnswers() {
        if (this.state.showMultipleRepliesOverlay == false)
            return null;
        return <div style={{...modalOverlay,}}>
            <div className={overlayContentStyle}>
                <table>
                    {this.state.multipleRepliesList.map((item: string) => (
                        <tr>
                            <td className={textBoxStyle}>{item}</td>
                        </tr>
                    ))}
                </table>
                <br/>
                <button className={buttonStyle} onClick={this.closeOverlay}>Close</button>
            </div>
        </div>;
    }

    render() {
        if (this.state.conversationList.length == 0) {
            var entry: PlanInfo|undefined = plansMap.get(this.state.planName);

            if (entry != null && entry != undefined) {
                this.addResponse(entry.systemPrompt, "system")
                if (entry.userContent != null)
                    this.addResponse(entry.userContent, "user")
            }
        }

        return <div style={{visibility: this.props.hidden === true ? 'hidden' : 'visible', width: '90%', height: '90%', overflow: 'auto', border: '0px solid #000'}}
                    className={"ll_v_c match_width match_height"}>
            <table>
                <thead>
                <tr>
                    <td><label>Post process reply:</label></td>
                    <td><input type="checkbox" checked={this.state.doPostProcessing} onChange={this.toggleDoPostProcessing}/></td>
                </tr>
                <tr>
                    <td><label>Generate multiple replies:</label></td>
                    <td>
                        <input type="text" className={textBoxStyle} value={this.state.numberOfRepliesToGenerate != null ? this.state.numberOfRepliesToGenerate : ""} onChange={(event) => this.setNumberOfRepliesToGenerate(event.target.value)}/>
                        <button className={smallButtonStyle} onClick={this.generateMultipleReplies}>Generate multiple responses</button>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><label>Plan:</label></td>
                    <td>{this.renderPlanDropDown()}</td>
                </tr>
                <tr>
                    <td><label>LLM:</label></td>
                    <td>{this.renderModelDropDown()}</td>
                </tr>
                {this.state.conversationList.map((item:conversationListEntry, index:number) => this.renderRow(item, index))}
                <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>
                        <button className={buttonStyle} onClick={this.gptTurn} disabled={this.state.waitingForChatCompletion}>{this.state.waitingForChatCompletion ? "Thinking..." : "Continue"}</button>
                        <button className={buttonStyle} onClick={this.restartConversation}>Restart Conversation</button>
                    </td>
                </tr>
                </tbody>
            </table>
            {this.renderOverlayMultipleAnswers()}
        </div>;
    }

    componentDidMount() {
        XhrHttpModule.createRequest(HttpMethod.GET, "get-jwt")
            .setRelativeUrl("/v1/token/jwt")
            .setUrlParams({exp: (currentTimeMillies() + Day).toString(), unitId: this.unitId})
            .executeSync().then((jwt) => this.jwt = jwt as string);
    }
}