import {_clearInterval, _setInterval, Minute, Module} from "@intuitionrobotics/ts-common";
import {BaseHttpRequest, HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {ThunderDispatcher, ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {BaseRuntimeStatus} from "@app/ir-q-app-common/types/runtime-status";
import {ApiGetBQ, ApiListOnboardingTimes, ApiListUnits, ApiRunTimeStatus, BQData, OnboardingTimeUnit} from "@app-sp/app-shared/api";
import {DeviceIdentity, DeviceType, FullUnitConfig, Response_Identities, Unit} from "@app/ir-q-app-common/types/units";
import {PmStatus} from "@app-sp/app-shared/package-manager";
import {KasperoDeleteUnitMetadata, KasperoGetIdentities, KasperoQueryMetadata, KasperoUpdateMetadata} from "@app/ir-q-app-common/types/api";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup, CONST_SP_Comment, DB_UnitMetadata, RenderMapMetaData} from "@app/ir-q-app-common/types/unit-config";
import Page_DevicePairing from "../app/pages/pairing/Page_DevicePairing";
import {Clause_Where} from "@intuitionrobotics/firebase";
import {FirebaseListenerModule, OnPairsRetrieved} from "@modules/FirebaseListenerModule";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import * as moment from "moment-timezone";
import {ActivationsModule} from "@modules/ActivationsModule";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {getAliasAndConfig} from "../app/pages/units/utils";
import {DeviceVersion, getDeviceModel} from "@app-sp/app-shared/utils";
import * as rs from "../res/data/sample_rs.json"

type Config = {
    dbName: string
}

export interface OnMetadataUpdated {
    __onMetadataUpdated(): void;
}

const dispatch_onMetadataUpdated = new ThunderDispatcher<OnMetadataUpdated, "__onMetadataUpdated">("__onMetadataUpdated");

export interface OnUnitInfoReturned {
    __onUnitInfoReturned(unit: Unit): void;
}

export interface OnDeviceUpair {
    onDeviceUnpair(): void;

    onUnpairFailed(message: string): void;
}

const timeFormat = "hh:mm A";

export const RequestKey_GetIdentities = "kaspero-get-identities";
export const RequestKey_FetchUnitsFullList = "kaspero-full-list";
export const RequestKey_FetchUnitsList = "FetchUnitsList";
export const RequestKey_FetchUnitsOnboardingTimes = "FetchOnboardingTimes";
export const RequestKey_FetchUnitStatus = "FetchUnitStatus";
export const RequestKey_UpdateUnitMetadata = "UpdateUnitMetadata";
export const RequestKey_DeleteUnitMetadata = "DeleteUnitMetadata";
export const RequestKey_GetUnitStatusTable = "get-unit-status-table";


export const RequestKey_QueryUnitMetadata = "QueryUnitMetadata";

export type UiPmStatus = {
    [unitId: string]: PmStatus[]
}

type DeviceVersions = { [k in DeviceType]?: DeviceVersion };

export type DeviceView = {
    version: string | null
    timestamp: number | null
    tz: string | null
    stricklandState: string | null
    androidVersion: number | null
    isOnError: boolean | null
    errorCodes: string | null
    flashPartitionsInfo: string | null
}

export type SomView = DeviceView & {
    manualShutdown?: boolean
}
export type UnitView = {
    som?: Partial<SomView>
    tablet?: Partial<DeviceView>
    env?: string,
    timestamp?: Date
};

export class UnitsModule_Class
    extends Module<Config>
    implements OnPairsRetrieved {
    private metadataUpdateTs: number = 0;


    constructor() {
        super("UnitsModule");
        this.setConfig({
            dbName: "elliq-env-dev"
        })
    }

    private runtimeStatus?: { [unitId: string]: BaseRuntimeStatus };
    private unitIdentitiesMap: { [unitId: string]: FullUnitConfig | undefined } = {};
    private deviceIdentities?: Response_Identities;
    private pmStatus: UiPmStatus = {};
    private unitsUpdate?: number;
    private unitMeta: { [unitId: string]: DB_UnitMetadata<any>[] } = {};
    private onboardingTimes: { [unitId: string]: OnboardingTimeUnit } = {};

    private dispatcher_onUnitInfoReturned = new ThunderDispatcher<OnUnitInfoReturned, "__onUnitInfoReturned">("__onUnitInfoReturned");

    public fetchRuntimeStatus = (interval: number, unit?: Unit) => {
        this.fetchRuntimeStatusImpl(unit);
        // No more
        this.unitsUpdate = _setInterval(() => this.fetchRuntimeStatusImpl(unit), interval);
    };

    public fetchRuntimeStatusImpl = (unit?: Unit) => {
        if (unit)
            return this.fetchUnitInfo(unit.unitId, unit.product);

        this.fetchRuntimeStatusCall();
    };

    public fetchOnboardingTimesImpl = () => {
        XhrHttpModule
            .createRequest<ApiListOnboardingTimes>(HttpMethod.GET, RequestKey_FetchUnitsOnboardingTimes)
            .setRelativeUrl("/v1/elliq/onboarding-time")
            .setLabel(`Fetching Onboarding times of units`)
            .setTimeout(Minute)
            .setOnError(`Error listing onboarding times`)
            .execute(response => {
                this.onboardingTimes = response;
            });
    };

    getOnboardingTimesByUnit = (unit: Unit) => this.onboardingTimes[unit.unitId]

    private bqData: BQData = {};

    cancelFetching() {
        if (this.unitsUpdate)
            _clearInterval(this.unitsUpdate);
    }

    getRuntimeStatus = (unitId: string): BaseRuntimeStatus | undefined => this.runtimeStatus?.[unitId];

    isRuntimeStatusLoaded = (): boolean => !!this.runtimeStatus;

    getSomeRuntimeStatus = () => {
        if (this.runtimeStatus && Object.keys(this.runtimeStatus).length > 0) {
            return this.runtimeStatus[Object.keys(this.runtimeStatus)[0]]
        }

        return rs;
    }

    async downloadCsv(list: string[]) {
        const elliqMetas = this.unitMeta[Elliq_ProductKey];
        if (!elliqMetas || Object.keys(elliqMetas).length < 2) {
            try {
                await this.queryMetadataSync({dataKey: CONST_KS_UserGroup});
            } catch (e) {
                ToastModule.toastError("Failed to download csv, please try again later")
            }
        }
        if (!this.isRuntimeStatusLoaded()) {
            try {
                await FirebaseListenerModule.getRsSync();
            } catch (e) {
                ToastModule.toastError("Failed to download csv, please try again later")
            }
        }

        if (!list.length) {
            ToastModule.toastError("Missing unit info, please try again later")
            return;
        }
        const rs = this.runtimeStatus;
        if (!rs) {
            ToastModule.toastError("Missing unit info, please try again later")
            return;
        }

        const csv = list.reduce((acc: string, unitId) => {
            const pair = this.getIdentity(unitId);
            const activation = ActivationsModule.getActivation(unitId);
            const unitEnv = this.getUnitUserGroup(unitId);
            if (!pair && !activation)
                return acc;

            const unitData = rs[unitId];
            const auditBy = activation?.blame || pair?._audit?.auditBy;
            const auditAt = activation?.registration_date || pair?._audit?.auditAt.timestamp;
            const when: string = moment.utc(auditAt).format("YYYY-MM-DD hh:mm:ss") || "";
            const somSerial = activation?.som_device_id || pair?.identities[0].serial;
            const tabletSerial = activation?.tablet_device_id || pair?.identities[1].serial;
            const preInstall = !!unitData ? "false" : "true";
            const isActivation = !activation ? "false" : "true";
            return `${acc}${unitId},${unitEnv},${auditBy},${when},${somSerial},${tabletSerial},${preInstall},${isActivation}` + "\n";
        }, "UnitId,Env,WhoPaired,WhenPaired,SomSerial,TabletSerial,PreInstall,Activation\n");
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', `units_${moment().format("YYYY-MM-DD")}.csv`)
        document.body.appendChild(a)
        if (document.createEvent) {
            const event = document.createEvent("MouseEvents");
            event.initEvent("click", true, true);
            a.dispatchEvent(event);
        } else {
            a.click();
        }
        document.body.removeChild(a);
    }

    // private getUnitCache = (unit: FullUnitConfig) => new StorageKey(this.getUnitCacheKey(unit));

    // private getUnitCacheKey = (unit: FullUnitConfig) => `qUnit-${unit.product}-${unit.unitId}`;

    // setUnitCache = (unit: FullUnitConfig) => {
    //     this.getUnitCache(unit).set(unit);
    // };

    getIdentity = (unitId: string): FullUnitConfig | undefined => this.unitIdentitiesMap[unitId];

    getPmStatus = (unitId: string) => this.pmStatus[unitId];

    onUnpairClick(unitId: string, product: string) {
        Page_DevicePairing.showUnpairDialog(unitId, product);
    }

    private fetchRuntimeStatusCall = () => {
        XhrHttpModule
            .createRequest<ApiListUnits>(HttpMethod.GET, RequestKey_FetchUnitsList)
            .setRelativeUrl("/v1/elliq/list")
            .setLabel(`Fetching Units`)
            .setTimeout(Minute)
            .setOnError(`Error listing units`)
            .execute(async response => {
                this.setRS(response.units);
            });
    };

    setRS(rs: { [unitId: string]: BaseRuntimeStatus }) {
        this.runtimeStatus = rs;
    }

    fetchPairedUnitsImpl = () => {
        FirebaseListenerModule.getFromDBPairsAndDevices(() => {
            new ThunderDispatcher<OnRequestListener, "__onRequestCompleted">("__onRequestCompleted")
                .dispatchUI(RequestKey_FetchUnitsFullList, true);
        })
        // XhrHttpModule
        //     .createRequest<KasperoUnitFullList>(HttpMethod.GET, RequestKey_FetchUnitsFullList)
        //     .setRelativeUrl("/v1/unit/full-list")
        //     .setLabel(`Fetching Unit List`)
        //     .setOnError(`Error listing units`)
        //     .execute(async response => {
        //         this.unitIdentities = [];
        //         this.clearUnitsCache();
        //         this.onPairsRetrieved(response);
        //     });
    };

    fetchDevices = () => {
        XhrHttpModule
            .createRequest<KasperoGetIdentities>(HttpMethod.GET, RequestKey_GetIdentities)
            .setRelativeUrl("/v1/kaspero/get-identities-manager")
            .setLabel(`Fetching Identities List`)
            .setOnError(`Error listing identities`)
            .execute(async response => {
                this.deviceIdentities = response;
            });
    }

    getDeviceIdentities() {
        return this.deviceIdentities
    }

    clearPairs = () => {
        this.unitIdentitiesMap = {};
    };


    __onPairsRetrieved = (unitConfigs: FullUnitConfig[]) => {
        for (const pair of unitConfigs) {
            this.unitIdentitiesMap[pair.unitId] = pair;
        }
    }

    queryUnitMetadata = (query: Clause_Where<DB_UnitMetadata<any>>) => {
        // Keep the
        if (this.metadataUpdateTs < Date.now() - Minute)
            dispatch_onMetadataUpdated.dispatchUI();

        this.queryMetadataImpl(query).execute(async meta => {
            this.onMetadataUpdated(meta);
            this.metadataUpdateTs = Date.now();
        });
    };

    async queryMetadataSync(query: Clause_Where<DB_UnitMetadata<any>>) {
        const resp = await this.queryMetadataImpl(query).executeSync();
        this.onMetadataUpdated(resp);
        return resp;
    }

    private queryMetadataImpl(query: Clause_Where<DB_UnitMetadata<any>>) {
        return XhrHttpModule
            .createRequest<KasperoQueryMetadata>(HttpMethod.POST, RequestKey_QueryUnitMetadata)
            .setRelativeUrl("/v1/unit/metadata/query")
            .setLabel(`Querying unit metadata`)
            .setJsonBody({where: query})
            .setOnError(() => {
                ToastModule.toastError(`Error querying unit metadata`);
                dispatch_onMetadataUpdated.dispatchUI();
            });
    }

    updateUnitMetadata = (unitIds: string[], metadata: Partial<RenderMapMetaData>) => {
        this.getUpdateUnitMetadataRequest(unitIds, metadata)
            .execute(async meta => {
                this.onMetadataUpdated(meta);
            });
    };

    getUpdateUnitMetadataRequest = (unitIds: string[], metadata: Partial<RenderMapMetaData>): BaseHttpRequest<KasperoUpdateMetadata> => {
        return XhrHttpModule
            .createRequest<KasperoUpdateMetadata>(HttpMethod.POST, RequestKey_UpdateUnitMetadata)
            .setRelativeUrl("/v1/unit/metadata/update")
            .setLabel(`Updating unit metadata`)
            .setJsonBody({unit: unitIds.map(u => ({unitId: u, product: Elliq_ProductKey})), metadata})
            .setOnError(() => {
                ToastModule.toastError(`Error updating unit metadata`);
                dispatch_onMetadataUpdated.dispatchUI();
            })
            .setOnSuccessMessage(`Metadata updated for units ${unitIds.join()}`)
    };


    onMetadataUpdated = (meta: DB_UnitMetadata<any>[]) => {
        meta.forEach((m: DB_UnitMetadata<any>) => {
            if (!this.unitMeta[m.unitId]) {
                this.unitMeta[m.unitId] = [m]
                return;
            }

            let idx = this.unitMeta[m.unitId].findIndex(d => d.dataKey === m.dataKey);
            if (idx === -1)
                idx = this.unitMeta[m.unitId].length;

            this.unitMeta[m.unitId][idx] = m;
        });
        dispatch_onMetadataUpdated.dispatchUI();
    };

    deleteUnitMetadata = (unitId: string, product: string, key: string) => {
        XhrHttpModule
            .createRequest<KasperoDeleteUnitMetadata>(HttpMethod.GET, RequestKey_DeleteUnitMetadata)
            .setRelativeUrl("/v1/unit/metadata/delete")
            .setLabel(`Deleting unit metadata`)
            .setUrlParams({unitId, product, key: key as keyof RenderMapMetaData})
            .setOnError(() => {
                ToastModule.toastError(`Error updating unit metadata`);
                dispatch_onMetadataUpdated.dispatchUI();
            })
            .setOnSuccessMessage(`Metadata updated for unit ${unitId}`)
            .execute(() => {
                this.unitMeta[unitId] = this.unitMeta[unitId].filter(m => m.dataKey !== key);
                dispatch_onMetadataUpdated.dispatchUI();
            });
    };

    getMetadatas = () => this.unitMeta;
    getUnitMetadata = (unitId: string) => this.unitMeta[unitId];

    getUnitUserGroup = (unitId: string) => {
        const env = this.unitMeta[unitId]?.find(meta => meta.dataKey === CONST_KS_UserGroup)?.data;
        if (env)
            return env;

        return this.getIdentity(unitId)?.comment;
    }

    getUnitSubUserGroup = (unitId: string) => {
        return this.unitMeta[unitId]?.find(meta => meta.dataKey === CONST_KS_SubUserGroup)?.data;
    }

    upsertPMStatus(pmStatus: PmStatus) {
        // TODO: need to handle this
        const unit = UnitsManagerModule.getUnitBasic(pmStatus.unitId);
        if (unit && unit.activation && unit.somSerial !== pmStatus.deviceId && unit.tabletSerial !== pmStatus.deviceId)
            return;

        const identity = this.getIdentity(pmStatus.unitId);
        // Unfortunately if the unit is activation the value under pmStatus.sha256 is actually the serial number so the check should be skipped if activation
        if (unit && unit.pair && !unit.activation && identity && !identity.sha256.includes(pmStatus.sha256))
            return;

        const statuses = this.pmStatus[pmStatus.unitId];
        if (!statuses)
            return this.pmStatus[pmStatus.unitId] = [pmStatus];

        const findIndex = statuses.findIndex(s => s.deviceType === pmStatus.deviceType);
        if (findIndex === -1)
            return statuses.push(pmStatus);

        // assume only the latest status per unitId and deviceType is the relevant
        if (statuses[findIndex].timestamp.timestamp > pmStatus.timestamp.timestamp)
            return;

        statuses[findIndex] = pmStatus;
    }

    removePMStatus(pmStatus: PmStatus) {
        const statuses = this.pmStatus[pmStatus.unitId];
        if (!statuses)
            return;

        const findIndex = statuses.findIndex(s => s._id === pmStatus._id);
        if (findIndex === -1)
            return;

        statuses.splice(findIndex, 1);
    }

    private fetchUnitInfo = (unitId: string, product: string) => {
        const params: { unitId: string, product: string, isActivation?: string } = {unitId, product};

        if (UnitsManagerModule.getUnitBasic(unitId)?.activation)
            params.isActivation = "true"

        XhrHttpModule
            .createRequest<ApiRunTimeStatus>(HttpMethod.GET, RequestKey_FetchUnitStatus)
            .setUrlParams(params)
            .setRelativeUrl(`/v1/elliq/status`)
            .setLabel(`Fetching Unit details: ${unitId}`)
            .setOnError(`Error getting unit ${unitId} details`)
            .execute(async response => {
                this.runtimeStatus = this.runtimeStatus || {};
                if (response.runtimeStatus)
                    this.setRSForUnit(unitId, response.runtimeStatus);

                if (response.unitIdentity) {
                    const unitConfig = {
                        ...response.unitIdentity,
                        identities: response.unitIdentity.devices
                    };
                    this.__onPairsRetrieved([unitConfig]);
                }

                this.dispatcher_onUnitInfoReturned.dispatchUI({unitId, product});
            });
    };

    setRSForUnit(unitId: string, runtimeStatus: BaseRuntimeStatus) {
        this.runtimeStatus = this.runtimeStatus || {};
        this.runtimeStatus[unitId] = runtimeStatus;
    }

    uploadGeneralComment = (unitId: string, comment: string) => {
        this.updateUnitMetadata([unitId], {[CONST_SP_Comment]: comment});
    }

    getGeneralComment = (unitId: string) => {
        return this.getUnitMetadata(unitId)?.find(meta => meta.dataKey === CONST_SP_Comment)?.data;
    }

    toggleUnitMetadata = (unit: Unit, key: keyof RenderMapMetaData) => {
        const oldVal: undefined | boolean = this.getMetadataValue(unit, key);
        const newVal = !oldVal;
        this.updateUnitMetadata([unit.unitId], {[key]: newVal})
    }

    public getMetadataValue(unit: Unit, key: string, defaultVal?: any) {
        const val = this.getMetadata(unit, key);
        if (!val)
            return defaultVal;

        return val.data;
    }

    public getMetadata(unit: Unit, key: string) {
        const metas = this.getUnitMetadata(unit.unitId);
        if (!metas)
            return;

        return metas.find(m => m.dataKey === key);
    }

    fetchUnitStatusTable = () => {
        XhrHttpModule
            .createRequest<ApiGetBQ>(HttpMethod.GET, RequestKey_GetUnitStatusTable)
            .setRelativeUrl(`/v1/bq/get`)
            .setLabel(`Fetching Unit Status Table`)
            .setOnError(`Error Fetching Unit Status Table`)
            .execute(async response => {
                this.bqData = response;
            });
    };

    getBQData = () => this.bqData;

    getDevicesVersions = (unitId: string): DeviceVersions => {
        const pairBasic = UnitsManagerModule.getUnitBasic(unitId);
        if (!pairBasic)
            return {};

        // TODO: get the unit hardware version for new activation unit
        if (pairBasic.activation)
            return {
                som: this.getDeviceVersion(pairBasic.unitId, {type: 'som', serial: pairBasic.somSerial}),
                tablet: this.getDeviceVersion(pairBasic.unitId, {type: 'tablet', serial: pairBasic.tabletSerial})
            };

        const oldPair = this.getIdentity(unitId);
        if (!oldPair)
            return {};

        if (oldPair?.softwareOnly)
            return {som: "SO"};

        return oldPair.identities.reduce((acc: DeviceVersions, device: DeviceIdentity) => {
            return {...acc, [device.type]: this.getDeviceVersion(oldPair.unitId, device)};
        }, {})
    }


    getDeviceVersion = (unitId: string, device: {
        version?: string,
        type: DeviceType,
        serial: string
    }): DeviceVersion => {
        const androidNumber = this.getAndroidVersion(unitId, device.type)
        return getDeviceModel(unitId, device, androidNumber);
    };

    getUnitView(unitId: string): UnitView {
        return this.getUnitViewImpl(unitId) || {};
    }

    private getUnitViewImpl(unitId: string) {
        return this.unitViews.get(unitId);
    }

    getVersion(unitId: string, deviceType: DeviceType) {
        const rs = this.runtimeStatus?.[unitId];
        if (rs)
            return rs?.[deviceType]?.unit_config?._originalVersion || rs?.[deviceType]?.version_name

        const unitView = this.unitViews.get(unitId);
        return unitView?.[deviceType]?.version
    }

    private unitViews: Map<string, UnitView | undefined> = new Map();

    setUnitViews(unitViews: Map<string, UnitView | undefined>) {
        this.unitViews = unitViews
    }

    private getAndroidVersion(unitId: string, type: DeviceType) {
        const runtimeStatus = this.getRuntimeStatus(unitId);
        if (runtimeStatus?.[type]?.android_version)
            return runtimeStatus?.[type]?.android_version

        const unitView = this.getUnitView(unitId);
        if (unitView?.[type]?.androidVersion)
            return unitView?.[type]?.androidVersion;
    }

    getDeviceSha(unitId: string, deviceType: DeviceType) {
        const unitBasic = UnitsManagerModule.getUnitBasic(unitId);
        if (!unitBasic)
            return null;

        if (unitBasic.pair) {
            const pair = this.getIdentity(unitId);
            return pair?.identities?.find((ident: DeviceIdentity) => ident.type === deviceType)?.sha256;
        }

        return null;
    }

    getEnv = () => this.config.dbName;

    public getASCIISumNumber(unitId: string, maxValue: number = 360) {
        if (maxValue === 0)
            return 0;

        // Step 1: Convert String to Unique Number
        let stringHash: number = 0;
        for (let i = 0; i < unitId.length; i++)
            stringHash += unitId.charCodeAt(i);

        // Step 2: Normalize the Number
        return stringHash % maxValue; // Modulus with maxValue to fit within 0 to maxValue range
    }

    getRcInstallTime(unitId: string): { [device: string]: string | undefined } | void {
        const {configId} = getAliasAndConfig(unitId);
        if (!configId)
            return;
        const config = PackageManagerModule.getConfig(configId);
        if (!config?.releaseCandidate)
            return;

        const unitView = this.getUnitView(unitId);
        if (!unitView)
            return;

        let somInstallationTime;
        let tabletInstallationTime;
        const asciiSumNumber = this.getASCIISumNumber(unitId);

        const fromV = config.releaseCandidateFromVersion;
        const toV = config.releaseCandidateToVersion;
        if (fromV || toV) {
            const somTZ = unitView.som?.tz;
            const somVersion = unitView.som?.version;
            if (somTZ && somVersion && (!fromV || somVersion >= fromV) && (!toV || somVersion <= toV)) {
                const somMoment = moment.tz(somTZ).startOf("day").add(23, "hour").add(asciiSumNumber, "minutes");
                somInstallationTime = moment(somMoment.valueOf()).format(timeFormat);
            }

            const tabletTZ = unitView.tablet?.tz;
            const tabletVersion = unitView.tablet?.version;
            if (tabletTZ && tabletVersion && (!fromV || tabletVersion >= fromV) && (!toV || tabletVersion <= toV)) {
                const tabletMoment = moment.tz(tabletTZ).startOf("day").add(23, "hour").add(asciiSumNumber, "minutes");
                tabletInstallationTime = moment(tabletMoment.valueOf()).format(timeFormat);
            }
        }

        return {
            som: somInstallationTime,
            tablet: tabletInstallationTime
        };
    }
}

export const UnitsModule = new UnitsModule_Class();
