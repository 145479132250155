import * as React from "react";
import {useContext, useState} from "react";
import {dateOptions, diskMemoryInUseOptions, errorOptions, installationOptions, rcOptions, stricklandOptions, UnitIdentitiesType, VersionStatus} from "./utils";
import TableCell from "@mui/material/TableCell";
import {SortOrder} from "@components/table/consts";
import {AutoComplete} from "@components/AutoComplete";
import {ToastModule, TS_Checkbox, TS_Input, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {UiEnvItem} from "@modules/DataEnvsModule";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Api_RS_Query} from "@app/ir-q-app-common/types/runtime-status";
import {LoadingContext} from "../../App";
import TextField from "@mui/material/TextField";
import {useUnitExists} from "@consts/utils";
import {UnitsModule} from "@modules/UnitsModule";

interface HeadersProps {
    filters: { [index: string]: string };
    selectedDateRange: { [index: string]: (string | { from: number, to: number }) }
    onDateRangeChange: (id: string, selectedDateRange: string) => void
    sortOrder: SortOrder;
    sortBy: string;
    onFilterNewValueChange: (value: string, id: string) => void
    onSortClick: (id: string) => void
    frozen: boolean
    onFrozenSelected: () => void
    tableHeaderConfig: string[]
    onTableHeaderConfigChange: (list: string[]) => void
    envs: UiEnvItem[]
    batch: boolean
    checkAll: boolean
    onCheckAll: () => void
}

interface HeadersState {
    dragItem?: string
    dragOverItem?: string
    tableHeaderConfig: string[]
    loading: boolean
    modalOpen: boolean
}

export class UnitsTableHead
    extends React.Component<HeadersProps, HeadersState> {

    constructor(props: HeadersProps) {
        super(props);
        this.state = {
            tableHeaderConfig: [...this.props.tableHeaderConfig],
            loading: false,
            modalOpen: false
        }
    }

    componentDidUpdate(prevProps: Readonly<HeadersProps>, prevState: Readonly<HeadersState>, snapshot?: any) {
        const prevHead = prevProps.tableHeaderConfig;
        const currentHead = this.props.tableHeaderConfig;
        if (prevHead.length !== currentHead.length
            || prevHead.some((e, idx) => currentHead[idx] !== e)
        )
            this.setState({tableHeaderConfig: [...currentHead]})
    }

    private getDropDownOptions = (id: string): string[] | undefined => {
        switch (id) {
            case "unitIdentity":
                return UnitIdentitiesType;
            case "versionStatus":
                return Object.values(VersionStatus);
            case "installation":
                return installationOptions;
            case "somTime":
            case "tabletTime":
                return dateOptions;
            case "systemErrors":
                return errorOptions;
            case "handshake":
                return stricklandOptions.map(o => o.toLowerCase())
            case "alive":
                return ["true", "false"]
            case "deviceVersion":
                return ["1.0", "1.2", "1.0_so", "3.0_SKWTH", "3.0_PRTCH", "3.0"]
            case "userGroup":
                return this.props.envs.map(e => e.label)
            case "aliasConfig":
                const aliases = Object.values(PackageManagerModule.getAliases());
                const configs = Object.values(PackageManagerModule.getConfigs());
                const arr = new Set<string>();
                aliases.forEach(x => arr.add(x.name));
                configs.forEach(x => arr.add(x.name));
                return [...arr]
            case "filesystemMemoryUsed":
                return diskMemoryInUseOptions;
            case "isRc":
                return rcOptions;
        }
    };

    private drop = () => {
        const dragItem = this.state.dragItem;
        const dragOverItem = this.state.dragOverItem;
        if (!dragItem || !dragOverItem)
            return this.setState({
                tableHeaderConfig: [...this.props.tableHeaderConfig],
                dragItem: undefined,
                dragOverItem: undefined
            });

        this.setState({
            dragItem: undefined,
            dragOverItem: undefined
        })

        let tableHeaderConfig = this.state.tableHeaderConfig
        if (!dragItem || dragOverItem === "DELETE")
            tableHeaderConfig = this.state.tableHeaderConfig.filter(e => e !== dragItem);

        this.props.onTableHeaderConfigChange(tableHeaderConfig);
    };

    private onEnter = (id: string) => {
        const onEnterIdx = this.state.tableHeaderConfig.indexOf(id);
        const dragItemContent = this.state.dragItem;
        if (onEnterIdx === -1 || !dragItemContent || dragItemContent === id)
            return;

        const copyListItems = [...this.state.tableHeaderConfig];

        const dragIdx = copyListItems.indexOf(dragItemContent);
        if (dragIdx === -1)
            return console.log(`cannot find ${dragItemContent} in list`)

        copyListItems.splice(dragIdx, 1);
        copyListItems.splice(onEnterIdx, 0, dragItemContent);

        this.setState({
            tableHeaderConfig: copyListItems,
            dragOverItem: id
        })
    }

    private getTitleCheckbox = () => <>
        {this.props.batch && <TS_Checkbox
            value={this.props.checkAll}
            checked={this.props.checkAll}
            onCheck={() => {
                this.props.onCheckAll()
            }}
            // width={'10px'}
            label={null}
        />}
        <img
            alt={"freeze"}
            onClick={() => {
                this.props.onFrozenSelected()
            }}
            style={{width: 18, marginRight: 4}}
            src={!this.props.frozen ? require("@res/images/freezing.png") : require("@res/images/icon__x.svg")}/>
    </>;

    private getQuery = () => <><img
        alt={"query"}
        onClick={() => {
            this.setState({modalOpen: true})
        }}
        style={{width: 18, marginLeft: 4, cursor: "pointer"}}
        src={require("@res/images/icon__search.svg")}
    />
        <QueryDialog
            open={this.state.modalOpen}
            onUnitsSelected={unitIds => {
                if (unitIds.length > 0)
                    this.props.onFilterNewValueChange(unitIds.join(","), "unitId")

                this.setState({modalOpen: false})
            }}/>
    </>

    private createSortableHeader = (id: string) => {
        const dropDownOptions = this.getDropDownOptions(id);
        const result = id.replace(/([A-Z])/g, " $1");
        const label = result.charAt(0).toUpperCase() + result.slice(1);
        const headers = this.state.tableHeaderConfig;
        return (
            <TableCell
                key={`tableHead-${id}`}
                align="left"
                style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                    padding: "auto",
                    paddingRight: headers[headers.length] === id ? 8 : 0,
                    position: "sticky",
                    top: 0
                }}>
                {this.state.dragItem === id && <Button
                    style={{position: "absolute"}}
                    className={`btn-remove-header ${`DELETE` === this.state.dragOverItem ? "hover" : ""}`}
                    size={"small"}
                    variant={"contained"}
                    color={"warning"}
                    onDragEnter={() => this.setState({dragOverItem: "DELETE"})}
                    onDragLeave={() => this.setState({dragOverItem: undefined})}
                >X</Button>}
                <div
                    draggable
                    onDragStart={(e) => {
                        // const image = new Image();
                        // image.src = icon__rightArrow
                        // e.dataTransfer.setDragImage(image, 0 ,0)
                        e.dataTransfer.dropEffect = "none"
                        this.setState({dragItem: id});
                    }}
                    onDragEnter={() => this.onEnter(id)}
                    onDragExit={() => this.setState({dragOverItem: undefined})}
                    onDragEnd={this.drop}
                    className="ll_v_c"
                    style={{
                        backgroundColor: this.getBackgroundColor(id),
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: "center"
                    }}>
                    <div className="ll_h_c">
                        {id === "unitId" && this.getTitleCheckbox()}
                        <div
                            id={id}
                            onClick={() => this.props.onSortClick(id)}
                            style={{fontWeight: this.props.sortBy === id ? (this.props.sortOrder === SortOrder.ASC ? 900 : 700) : 300}}
                        >{label}</div>
                        {id === "unitId" && this.getQuery()}
                    </div>
                    {!id.toLowerCase().includes("time") ? <div style={{width: "8rem", height: "100%", display: 'flex', paddingLeft: 5}}>
                            {this.renderFilterTextOrDropdown(dropDownOptions, id)}
                        </div> :
                        <div style={{width: "8rem", height: "100%", display: 'flex', paddingLeft: 5}}>
                            {this.renderFilterDate(dropDownOptions || [], id)}
                        </div>}
                </div>
            </TableCell>
        );
    };

    private getBackgroundColor(id: string) {
        if (this.state.dragItem === id)
            return "#c0c0c0ee"
        if (this.state.dragOverItem === id)
            return "#c0c0c099"
        return "gainsboro";
    }

    private renderFilterDate(dropDownOptions: string[], id: string) {
        const onChange = (selectedDateRange: string) => this.props.onDateRangeChange(id, selectedDateRange);
        const selectedDateRangeElement = this.props.selectedDateRange[id];
        return <AutoComplete<string>
            options={dropDownOptions}
            labelResolver={(value: string) => value}
            placeholder={'filter'}
            value={typeof selectedDateRangeElement === "string" ? selectedDateRangeElement : undefined}
            id={id}
            onType={onChange}
            onSelected={onChange}
            onAccept={onChange}
            showResult
            style={{borderRadius: 10, backgroundColor: "white"}}
            width={"-webkit-fill-available"}
        />
    }


    private renderFilterTextOrDropdown(dropDownOptions: string[] | undefined, id: string) {
        if (dropDownOptions && dropDownOptions.length !== 0)
            return this.renderAutocomplete(dropDownOptions, id)

        return <TS_Input
            value={this.props.filters[id]}
            onChange={this.props.onFilterNewValueChange}
            type={'text'}
            placeholder={'filter'}
            style={{borderRadius: 10, width: '100%', paddingLeft: 2}}
            id={id}
        />
    }

    renderAutocomplete(dropDownOptions: string[] | undefined, id: string) {
        if (!dropDownOptions)
            return;

        return <AutoComplete<string>
            id={id}
            placeholder={"filter"}
            labelResolver={(value: string) => value}
            options={dropDownOptions}
            value={this.props.filters[id]}
            onSelected={value => this.props.onFilterNewValueChange(value, id)}
            onAccept={value => this.props.onFilterNewValueChange(value, id)}
            showResult
            style={{borderRadius: 10, backgroundColor: "white"}}
            width={"-webkit-fill-available"}/>
    }

    render() {
        return <TableHead>
            <TableRow>
                {this.state.tableHeaderConfig.map(this.createSortableHeader)}
            </TableRow>
        </TableHead>;
    }
}

export interface SimpleDialogProps {
    open: boolean;
    onUnitsSelected: (unitIds: string[]) => void
}

function getSuggestions(key: string) {
    const strings = key.split(".");
    // remove last from strings
    strings.pop();
    const someRs = UnitsModule.getSomeRuntimeStatus();
    if (strings.length === 0)
        return Object.keys(someRs);

    let obj: any = someRs;
    for (let i = 0; i < strings.length; i++) {
        obj = obj[strings[i]];
    }
    if (['string', 'number', "boolean", "null", "undefined"].includes(typeof obj))
        return [key]

    return Object.keys(obj).map(k => `${strings.join(".")}.${k}`);
}

function QueryDialog(props: SimpleDialogProps) {
    const [key, setKey] = useState("")
    const [value, setValue] = useState("")
    const {setLoading} = useContext(LoadingContext);
    const {unitExists} = useUnitExists();
    const {open, onUnitsSelected} = props;
    const keyId = "query-key";
    const valId = "query-val";
    const disabled = !key || !value;
    const handleClose = () => onUnitsSelected([]);
    const onQuery = async () => {
        if (disabled)
            return;
        setLoading(true);
        try {
            const res = await XhrHttpModule.createRequest<Api_RS_Query>(HttpMethod.GET, "rs query")
                .setRelativeUrl("/v1/rs/query")
                .setUrlParams({key, value})
                .executeSync();

            const filtered = res.unitIds.filter(unitExists);
            ToastModule.toastInfo(`Found ${filtered.length} units`)
            onUnitsSelected(filtered);
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false);
        }
    }
    const optionsId = `${keyId}_options`;
    return <Dialog fullWidth maxWidth={"md"} onClose={handleClose} open={open}>
        <DialogTitle>Query RS</DialogTitle>
        <DialogContent>
            <Autocomplete
                freeSolo
                id="key-query-autocomplete"
                disableClearable
                options={getSuggestions(key)}
                onChange={(_, value) => setKey(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus
                        required
                        margin="dense"
                        id={keyId}
                        name={keyId}
                        onChange={(e) => {
                            setKey(e.target.value)
                        }}
                        value={key}
                        label="key"
                        type="text"
                        fullWidth
                        variant="standard"
                        inlist={optionsId}
                    />
                )}
            />
            <TextField
                required
                margin="dense"
                id={valId}
                name={valId}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                value={value}
                label="value"
                type="text"
                fullWidth
                variant="standard"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={onQuery}>Query</Button>
        </DialogActions>
    </Dialog>
}